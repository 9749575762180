import {combineReducers} from "@reduxjs/toolkit";
import BingoReducer from "./BingosReducers";
import selectedOptionReducer from "./SelectedOptionReducers";
import SessionReducer from "./SessionReducers";

const AdminReducer = combineReducers({
    bingoState:BingoReducer,
    sessionState:SessionReducer,
    selectedOptionState: selectedOptionReducer,
});
export default AdminReducer;