import axios from "axios";
import {CreateSessionModel, Sessions} from "../../domain/models/CreateSessionModel";
import {
    CLEAN_ALL_SESSIONS,
    CLEAN_SINGLE_SESSION,
    CREATE_SESSION,
    CREATE_SESSION_FAILED,
    CREATE_SESSION_SUCCESS, DELETE_SESSION, DELETE_SESSION_FAILED, DELETE_SESSION_SUCCESS,
    EDIT_SESSION,
    EDIT_SESSION_FAILED,
    EDIT_SESSION_SUCCESS,
    GET_ALL_SESSIONS,
    GET_ALL_SESSIONS_FAILED,
    GET_ALL_SESSIONS_SUCCESS,
    GET_SINGLE_SESSION,
    GET_SINGLE_SESSION_FAILED,
    GET_SINGLE_SESSION_SUCCESS
} from "../TypesAdmin/SessionTypes";
import {EditSessionModel} from "../../domain/models/EditSessionModel";
import {createSessionTesting, editSessionTesting, singleSessionTesting} from "../../shared/Utils/TestingApi/TestingApi";


export const getAllSessions = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    // console.log("excecuting get all bingos");
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://gniqduw5uknogscyjiu3sbzpoq0rpxup.lambda-url.us-east-1.on.aws/',
        headers: {
            'info_sessions': 'ok'
        }
    };
    dispatch({
        type: GET_ALL_SESSIONS,
        payload: 'loading'
    });
    axios.request(config)
        .then((response) => {
            let resData:Sessions = response.data as Sessions;
            console.log(response.data);
            const sortedList:Sessions = [...resData].sort((a, b) =>
                a.session_id - b.session_id
            );
            dispatch({
                type: GET_ALL_SESSIONS_SUCCESS,
                payload: sortedList
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_SESSIONS_FAILED,
                payload: error
            });
        });
}
export const getSingleSession = (session_id:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: singleSessionTesting,
        // url: singleSessionProduction,
        headers: {
            // 'x-api-key': 'EbwINfmf1Q2ypCqisPdgy26dWMmvDnD36gnWepw2',
            'info_session': 'ok',
            'session_id': session_id
        }
    };
    dispatch({
        type: GET_SINGLE_SESSION,
        payload: 'loaning'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: GET_SINGLE_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_SESSION_FAILED,
                payload: error
            });
        });
}
export const createSession = (data:CreateSessionModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: createSessionTesting,
        // url: createSessionProduction,
        headers: {
            // 'x-api-key': 'RsvupNHnLG1DpC06zzRkd3ghBLdGqAZD1gbVuvi8',
            'create_session': 'ok',
            'bingo_limit': data.bingo_limit,
            'card_limit': data.card_limit,
            'card_price': data.card_price,
            'session_name': data.session_name,
            'creator': data.creator,
            'scheduled_date': data.scheduled_date,
            'client': 'empresaX'
        }
    };
    dispatch({
        type: CREATE_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: CREATE_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: CREATE_SESSION_FAILED,
                payload: error
            });
        });
}
export const editSession = (data:EditSessionModel, session_id:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: editSessionTesting,
        // url: editSessionProduction,
        headers: {
            'edit_session': 'ok',
            'session_id': session_id,
            'new_bingo_limit': '',
            'new_card_limit': data.new_card_limit,
            'new_card_price': data.new_card_price,
            "new_session_name": data.new_session_name,
            "new_creator": data.new_creator,
            "new_scheduled_date": data.new_scheduled_date
        }
    };
    dispatch({
        type: EDIT_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_FAILED,
                payload: error
            });
        });
}
export const deleteSession = (session_id:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://gniqduw5uknogscyjiu3sbzpoq0rpxup.lambda-url.us-east-1.on.aws/',
        headers: {
            'delete_session': 'ok',
            'session_id': session_id
        }
    };
    dispatch({
        type: DELETE_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: DELETE_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_SESSION_FAILED,
                payload: error
            });
        });
}
export const cleanSingleSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SINGLE_SESSION,
        payload: null
    });
}
export const cleanAllSessions = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_SESSIONS,
        payload: []
    });
}