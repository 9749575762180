import {SwitchButtonStyled} from "./SwitchButtonStyled";
import {useState} from "react";

interface ISwitchButtonProps {
    switchProp: boolean;
    refButton: string;
}

export default function SwitchButton({
                                         switchProp,
                                         refButton,
                                     }: ISwitchButtonProps): JSX.Element {

    const [switchButton, setSwitchButton] = useState<boolean>(switchProp);

    function handleCllick(refButton: string) {
        console.log(refButton);
        if (refButton === 'button1') {
            setSwitchButton(!switchButton);
        }
        if (refButton === 'button2') {
            setSwitchButton(!switchButton);
        }
    }

    return (
        <SwitchButtonStyled>
            <div onClick={() => handleCllick(refButton)}
                 className={!switchButton ? "container-switch-button-off" : "container-switch-button-on"}>
                <div className={!switchButton ? "button-switch-off" : "button-switch-on"}>
                    {!switchButton ? <img alt="img-X" src="/icon/x.png"/> : <img alt="img-ok" src="/icon/cheque.png"/>}
                </div>
            </div>
        </SwitchButtonStyled>
    );
}