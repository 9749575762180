import {SecondarySidebarStyles} from "./SecondarySidebarStyles";
import {JSXElementConstructor, ReactElement, useEffect, useState} from "react";
import VideoComponent from "../VideoComponent/VideoComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {ListAllBingosModel} from "../../../domain/models/ListAllBingosModel";
import {cleanLastBallot, createBingo, getAllBingos, getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";
import {CreateBingoModel} from "../../../domain/models/CreateBingoModel";
import SweetAlert2 from "react-sweetalert2";
import {useForm} from "react-hook-form";
import {gameModes} from "../../../shared/Utils/GameModes";
import {updateSelectedOptions} from "../../../store/ActionsAdmin/SelectedOptionActions";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";

interface ISecondarySidebarProps {
    handleOpenModal: (type: TypeActionModal) => void;
}
interface formVideoTransmission {
    linkVideo:string;
}

export default function SecondarySidebar({
                                             handleOpenModal,
                                         }: ISecondarySidebarProps): JSX.Element {

    const [singleBingoSelected, setSingleBingoSelected] = useState<number>(0);
    const dispatch = useAppDispatch();
    const allBingos = useAppSelector((state) => state.bingoState.allBingos);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const loadingAllBingos = useAppSelector((state) => state.bingoState.loadingAllBingos);
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const loadingSingleSession = useAppSelector((state) => state.sessionState.loadingSingleSession);
    const [videoTransmission, setVideoTransmission] = useState<string>('');
    const {register, handleSubmit,  reset, watch} = useForm<formVideoTransmission>();
    const videoTransmissionLink = watch("linkVideo");

    async function handleDataSingleBingo(id_game_id: number) {
        setSingleBingoSelected(id_game_id);
        dispatch(getSingleBingo(id_game_id.toString())).then(r => {
            dispatch(updateSelectedOptions());
        });
    }

    function onNewBingo() {
        handleOpenModal('create_bingo');
    }

    function handleVideoTransmission(data: formVideoTransmission) {
        console.log(data)
        setVideoTransmission(data.linkVideo);
    }

    const onSubmit = (data:formVideoTransmission) => handleVideoTransmission(data);

    useEffect(() => {
        console.log(videoTransmissionLink);
    }, []);

    return (
        <SecondarySidebarStyles>
            {singleSession && (
                <div className="title-sidebar-secondary">{singleSession.session_name}</div>
            )}
            {singleSession && (
                <div className="container-info-session">
                    <div className="info-session">
                        <div className="info">
                            <span className="text-bold">Creación: </span>
                            <span className="text">{singleSession.creation_time}</span>
                        </div>
                        <div className="info">
                            <span className="text-bold">Inicio: </span>
                            <span className="text">{singleSession.edition_time}</span>
                        </div>
                    </div>
                    <div className="info-session">
                        <div className="info">
                            <span className="text-bold">Creador: </span>
                            <span className="text">{singleSession.creator}</span>
                        </div>
                        <div className="info">
                            <span className="text-bold">Jugadores Activos: </span>
                            <span className="text">{singleSession.players_info.active_players}</span>
                        </div>
                        <div className="info">
                            <span className="text-bold">Cartones en juego: </span>
                            <span className="text">{singleSession.players_info.card_quantity}</span>
                        </div>
                        <div className="info">
                            <span className="text-bold">Límite bingo: </span>
                            <span className="text">{singleSession.bingo_limit}</span>
                        </div>
                    </div>
                </div>
            )}
            <div className="container-list">
                <ul>
                    {!loadingAllBingos && allBingos && allBingos.length > 0 && allBingos.map((data) => (
                        <li className={singleBingoSelected === data.game_id ? 'list-background' : ''} onClick={() => handleDataSingleBingo(data.game_id)} key={data.game_id}>
                            {data.start_time === "" && data.end_time === "" &&
                                <img alt="check_ok" className="circle" src="/icon/iconAdmin/pause.svg"/>}
                            {data.start_time !== "" && data.end_time === "" &&
                                <img alt="check_ok" className="circle" src="/icon/iconAdmin/progress.svg"/>}
                            {data.start_time !== "" && data.end_time !== "" &&
                                <img alt="check_ok" className="circle" src="/icon/iconAdmin/check-green.svg"/>}
                            {data.bingo_name}
                        </li>
                    ))}
                    {loadingAllBingos && <li key={"loagingBingosLi"}>Cargando...</li>}
                    {!allBingos && !loadingAllBingos && <li key={"notLoadedBingosLi"}>No hay bingos creados</li>}
                </ul>
            </div>
            <ButtonComponent
                text="Nuevo juego"
                background="#88A90F 0% 0% no-repeat padding-box"
                color="#FFFFFF"
                width={70}
                onClick={onNewBingo}
            />
            <div className="container-transmission">
                <div className="transmission">
                    Transmisión
                </div>
                <VideoComponent videoTransmission={videoTransmission}/>
                <div className="link-transmission">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="text"
                            placeholder="Pegar link de transmi..."
                            {...register('linkVideo')}
                        />
                    </form>
                </div>
            </div>
        </SecondarySidebarStyles>
    );
}