import {SessionInfoStyles} from "./SessionInfoStyles";
import {ListAllBingosModel} from "../../../domain/models/ListAllBingosModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {gameModes} from "../../../shared/Utils/GameModes";
import SweetAlert2 from "react-sweetalert2";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {EditBingoModel} from "../../../domain/models/EditBingoModel";
import {editBingo, getAllBingos, getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";

interface ISessionInfoProps {
    handleOpenModal: (type: TypeActionModal) => void;
}

export default function SessionInfo({
                                        handleOpenModal,
                                    }: ISessionInfoProps): JSX.Element {

    const dispatch = useAppDispatch();
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);

    const blockedLaunch = useAppSelector((state) => state.bingoState.blockedLaunch);


    return (
        <SessionInfoStyles>
            <div className="session-info">
                <div className="logo-sesion">
                    {/*{singleBingo && singleBingo?.winners.length > 0 ? <img src="/img/imgAdmin/check_ok.png"/> : <img src="/img/imgAdmin/logo-sesion-info.svg"/>}*/}
                    {singleBingo && singleBingo.start_time === "" && singleBingo.end_time === "" &&
                        <img src="/icon/iconAdmin/pause.svg"/>}
                    {singleBingo && singleBingo.start_time !== "" && singleBingo.end_time === "" &&
                        <img src="/icon/iconAdmin/progress.svg"/>}
                    {singleBingo && singleBingo.start_time !== "" && singleBingo.end_time !== "" &&
                        <img src="/icon/iconAdmin/check-green.svg"/>}
                </div>
                <div className="text">
                    {/*{singleBingo && singleBingo?.winners.length > 0 ? <span className="blocked">Finalizado</span> : "En progreso"}*/}
                    {singleBingo && singleBingo.start_time === "" && singleBingo.end_time === "" &&
                        <span className="blocked">Iniciando</span>}
                    {singleBingo && singleBingo.start_time !== "" && singleBingo.end_time === "" &&
                        <span className="blocked">En progreso</span>}
                    {singleBingo && singleBingo.start_time !== "" && singleBingo.end_time !== "" &&
                        <span className="blocked">Finalizado</span>}
                </div>
            </div>

            <div className="prize-info">
                {singleBingo !== null ? <h2>{singleBingo.bingo_name}</h2> : <h2>?</h2>}
                <div className="session-date">
                    <span className="fixed-text">Fecha de Inicio: </span>
                    {singleBingo !== null ? <span className="variable-text">{singleBingo.start_time}</span> :
                        <span className="variable-text">?</span>}

                </div>
                <div className="session-date">
                    <span className="fixed-text">Tipo de Juego: </span>
                    {singleBingo !== null ?
                        <span className="variable-text">
                            {singleBingo.game_mode === 'any_row' ? 'Cualquier fila' : ''}
                            {singleBingo.game_mode === 'all_card' ? 'Todo el tarjetón' : ''}
                            {singleBingo.game_mode === 'any_col' ? 'Cualquier columna' : ''}
                            {singleBingo.game_mode === 'row_1' ? 'Primera fila' : ''}
                            {singleBingo.game_mode === 'row_2' ? 'Segunda fila' : ''}
                            {singleBingo.game_mode === 'row_3' ? 'Tercera fila' : ''}
                            {singleBingo.game_mode === 'row_4' ? 'Cuarta fila' : ''}
                            {singleBingo.game_mode === 'row_5' ? 'Quinta fila' : ''}
                            {singleBingo.game_mode === 'col_1' ? 'Primera columna' : ''}
                            {singleBingo.game_mode === 'col_2' ? 'Segunda columna' : ''}
                            {singleBingo.game_mode === 'col_3' ? 'Tercera columna' : ''}
                            {singleBingo.game_mode === 'col_4' ? 'Cuarta columna' : ''}
                            {singleBingo.game_mode === 'col_5' ? 'Quinta columna' : ''}
                            {singleBingo.game_mode === 'diagonal' ? 'Diagonal' : ''}
                            {singleBingo.game_mode === 'anti_diagonal' ? 'Antidiagonal' : ''}
                            {singleBingo.game_mode === 'shape_X' ? 'Forma X' : ''}
                            {singleBingo.game_mode === 'shape_L' ? 'Forma L' : ''}
                            {singleBingo.game_mode === 'corners' ? '4 Esquinas' : ''}
                        </span> :
                        <span className="variable-text">
                            ?
                        </span>
                    }
                </div>
                <div className="session-date">
                    <span className="fixed-text">Fecha de Creación: </span>
                    {singleBingo !== null ? <span className="variable-text">{singleBingo.creation_time}</span> :
                        <span className="variable-text">?</span>}

                </div>
            </div>

            <div className="second-info">
                <div className="pencil-logo" onClick={() => handleOpenModal('edit_bingo')}>
                    <img src="/img/imgAdmin/pencil-logo.svg"/>
                </div>
                <div className="prize">
                    <span className="item">Premio:</span>
                    {singleBingo !== null ? <span className="ref-prize">{singleBingo.prize}</span> :
                        <span className="ref-prize">?</span>}
                </div>
            </div>
        </SessionInfoStyles>
    );
}