import styled from "styled-components";

export const CardBingoStyled = styled.div`
  background: #0A0A0A 0% 0% no-repeat padding-box;
  border: 2px solid #EBEBEB;
  opacity: 0.35;
  width: 90%;
  height: 90%;
  border-radius: 5px;
  
  .serial {
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    padding: .2rem;
    font-size: .5em;
  }
  .container-number-card {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
    padding: 0 .6rem;
    
    .border-1 {
      width: 100%;
      height: 100%;
      border-bottom: 0.9187242388725281px solid #FFFFFF;
      border-right: 0.9187242388725281px solid #FFFFFF;
      color: blue;
    }
    .border-2 {
      width: 100%;
      height: 100%;
      border-bottom: 0.9187242388725281px solid #FFFFFF;
    }
    .border-3 {
      width: 100%;
      height: 100%;
      border-right: 0.9187242388725281px solid #FFFFFF;
    }
    .border-4 {
      width: 100%;
      height: 100%;
    }
  }
`