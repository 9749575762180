import {SectionMainStyled} from "./SectionMainStyled";
import SectionMainHeader from "../SectionMainHeader/SectionMainHeader";
import CardBingo from "../CardBingo/CardBingo";
import {dataCardBingo} from "../../../shared/Utils/dataCardBingo";

export default function SectionMain():JSX.Element {

    return (
        <SectionMainStyled>
            <SectionMainHeader/>
            <div className="container-card-bingo">
                <div className="card-bingo-grid">
                    {dataCardBingo && dataCardBingo.map((data) => (
                        <CardBingo dataNumber={data.dataNumber} serial={data.serialId}/>
                    ))}

                </div>
            </div>
        </SectionMainStyled>
    );
}