import {HomeStyled} from "./HomeStyled";
import Header from "../../Components/Header/Header";
import VideoComponent from "../../Components/VideoComponent/VideoComponent";
import InfoPlay from "../../Components/InfoPlay/InfoPlay";
import SectionMain from "../../Components/SectionMain/SectionMain";
import Footer from "../../Components/Footer/Footer";
import ComponentGamers from "../../Components/ComponentGamers/ComponentGamers";
import {useEffect} from "react";
import {secret} from "../../../../admin/shared/Utils/constants";
import {history} from "../../../../../App";

export default function Home():JSX.Element {
    useEffect(() => {

        let localuser = sessionStorage.getItem("user_secret");
        if(!localuser || localuser != secret){
            sessionStorage.removeItem("user_secret");
            history.push("/login")
        }

    }, []);

    return (
        <HomeStyled>
            <Header/>
            <div className="main">
                <div className="col-1">
                    <VideoComponent/>
                    <InfoPlay/>
                </div>
                <div className="col-2">
                    <SectionMain/>
                    <Footer/>
                </div>
                <div className="col-3">
                    <ComponentGamers/>
                    <div className="container-soccer-ball">
                        <img alt="soccer-ball" className="soccer-ball-img" src="/img/soccer_ball_big.png"/>
                        <span className="text">Donar</span>
                    </div>
                </div>
            </div>
        </HomeStyled>
    );
}