import styled from "styled-components";

export const SessionInfoStyles = styled.div`
  display: grid;
  grid-template-columns: 0.2fr .4fr 1fr;
  align-items: center;
  justify-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  margin: .5vw 1.5vw;
  height: 18%;
  
  .session-info{
    .logo-sesion{
      img{
        height: 10vh;
      }
    }
    .text{
      font-family: Poppins-Medium,sans-serif;
      text-align: left;
      font-size: 0.84em;
      letter-spacing: 0px;
      color: #686868;
      opacity: 1;
    }
  }
  
  .prize-info{
    h2{
      margin:0;
      font-weight: bold;
      font-size: 1.21em;
      text-align: left;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    
    .session-date{
      .fixed-text{
        font-weight: bold;
        font-size: .67em;
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
      }
      .variable-text{
        font-size: .67em;
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
      }
    }
    
  }
  
  .second-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 6rem;
    
    .pencil-logo{
      width: 100%;
      display: flex;
      justify-content: end;
      
      img{
        cursor: pointer;
        display: block;
        margin-right: 1rem;
      }
    }
    
    .prize{
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 50%;
      
      .item{
        font-weight: bold;
        font-size: 0.67em;
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
      }
      
      .ref-prize{
        font-size: 1em;
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
      }
    }
    
  }
  
`