import {CreateSessionStyles} from "./CreateSessionStyles";
import SweetAlert2 from "react-sweetalert2";
import {useForm} from "react-hook-form";
import {CreateSessionModel} from "../../../domain/models/CreateSessionModel";
import {EditSessionModel} from "../../../domain/models/EditSessionModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {createSession, getAllSessions} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {cardLimit, cardPrice, getTodayDate} from "../../../shared/Utils/ValidationformCreateSession";

interface ICreateSessionProps {
    handleCloseModal: () => void;
}

export default function CreateSession({
                                          handleCloseModal,
                                      }: ICreateSessionProps): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateSessionModel>();
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const loadingCreateSession = useAppSelector((state) => state.sessionState.CreatingSession);

    function handleCreateSession(data: CreateSessionModel) {
        console.log(data);
        dispatch(createSession(data)).then((r) => {
            dispatch(getAllSessions());
            reset();
            alertSuccess('Sesión creada con éxito');
            handleCloseModal();
        });
    }

    const onSubmit = (data: CreateSessionModel) => handleCreateSession(data);

    return (
        <CreateSessionStyles>
            <div className="button-close-modal" onClick={handleCloseModal}>X</div>
            <h1 className="title-bingo">Crear sesión</h1>
            <form className="form-bingo" onSubmit={handleSubmit(onSubmit)}>
                <div className="container-input">
                    <label htmlFor="bingo-limit">Límite de Bingo: </label>
                    <select
                        {...register("bingo_limit", {required: "Límite del bingo es requerido"})}
                        aria-invalid={errors.bingo_limit ? "true" : "false"}
                    >
                        <option className="placeholder-option" value="">--Selecciona el limite del bingo--</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="99">99</option>
                    </select>
                    {errors.bingo_limit?.type === 'required' &&
                        <span className="error" role="alert">{errors.bingo_limit?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="user">Limite de tarjetones: </label>
                    <input
                        placeholder="máximo 10 mínimo 1"
                        type="text"
                        {...register("card_limit", {
                            required: "el límite de tarjetones es requerido",
                            validate: cardLimit
                        })}
                        aria-invalid={errors.card_limit ? "true" : "false"}
                    />
                    {errors.card_limit?.type === 'required' &&
                        <span className="error" role="alert">{errors.card_limit?.message}</span>
                    }
                    {errors.card_limit?.type === 'validate' &&
                        <span className="error" role="alert">{errors.card_limit?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card-limit">Valor bingo: </label>
                    <input
                        placeholder="Precio del bingo"
                        type="text"
                        {...register("card_price", {
                            required: 'El precio del bingo es requerido',
                            validate: cardPrice
                        })}
                        aria-invalid={errors.card_price ? "true" : "false"}
                    />
                    {errors.card_price?.type === 'required' &&
                        <span className="error" role="alert">{errors.card_price?.message}</span>
                    }
                    {errors.card_price?.type === 'validate' &&
                        <span className="error" role="alert">{errors.card_price?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Nombre de la sesión: </label>
                    <input
                        placeholder="Nombre de la sesión"
                        type="text"
                        {...register("session_name", {required: "El nombre de la sesión es requerido"})}
                        aria-invalid={errors.session_name ? "true" : "false"}
                    />
                    {errors.session_name?.type === 'required' &&
                        <span className="error" role="alert">{errors.session_name?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Creador: </label>
                    <input
                        placeholder="Nombre del creador"
                        type="text"
                        {...register("creator", {required: "El nombre del creador es requerido"})}
                        aria-invalid={errors.creator ? "true" : "false"}
                    />
                    {errors.creator?.type === 'required' &&
                        <span className="error" role="alert">{errors.creator?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Fecha de cita: </label>
                    <input
                        type="date"
                        {...register("scheduled_date", {
                            required: "Fecha requerida",
                            validate: {
                                validDate: (value) => value >= getTodayDate() || "La fecha no puede ser menor a la fecha actual"
                            }
                        })}
                        aria-invalid={errors.scheduled_date ? "true" : "false"}
                    />
                    {errors.scheduled_date?.type === 'required' &&
                        <span className="error" role="alert">{errors.scheduled_date?.message}</span>
                    }
                    {errors.scheduled_date && (
                        <span className="error" role="alert">{errors.scheduled_date?.message}</span>
                    )}
                </div>
                <button className="button-bingo" disabled={loadingCreateSession} type="submit">{!loadingCreateSession ? "Nueva Sesion" : "Creando Sesion..."}</button>
            </form>
        </CreateSessionStyles>
    );
}