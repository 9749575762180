export const GET_ALL_BINGOS = 'GET_ALL_BINGOS';
export const GET_ALL_BINGOS_SUCCESS = 'GET_ALL_BINGOS_SUCCESS';
export const GET_ALL_BINGOS_FAILED = 'GET_ALL_BINGOS_FAILED';
export const GET_SINGLE_BINGO = 'GET_SINGLE_BINGO';
export const GET_SINGLE_BINGO_SUCCESS = 'GET_SINGLE_BINGO_SUCCESS';
export const GET_SINGLE_BINGO_FAILED = 'GET_SINGLE_BINGO_FAILED';
export const CREATE_BINGO = 'CREATE_BINGO';
export const CREATE_BINGO_SUCCESS = 'CREATE_BINGO_SUCCESS';
export const CREATE_BINGO_FAILED = 'CREATE_BINGO_FAILED';
export const LAUNCH_BALLOT = 'LAUNCH_BALLOT';
export const LAUNCH_BALLOT_SUCCESS = 'LAUNCH_BALLOT_SUCCESS';
export const LAUNCH_BALLOT_FAILED = 'LAUNCH_BALLOT_FAILED';
export const START_GAME = 'START_GAME';
export const START_GAME_SUCCESS = 'START_GAME_SUCCESS';
export const START_GAME_FAILED = 'START_GAME_FAILED';
export const EDIT_BINGO = 'EDIT_BINGO';
export const EDIT_BINGO_SUCCESS = 'EDIT_BINGO_SUCCESS';
export const EDIT_BINGO_FAILED = 'EDIT_BINGO_FAILED';
export const CLEAN_SINGLE_BINGO = 'CLEAN_SINGLE_BINGO';
export const CLEAN_ALL_BINGOS = 'CLEAN_ALL_BINGOS';
export const UPDATE_LAST_BALLOT = 'UPDATE_LAST_BALLOT';
export const CLEAN_LAST_BALLOT = 'CLEAN_LAST_BALLOT';
