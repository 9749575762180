import styled from "styled-components";

export const ServerStatusStyles = styled.div`
  display: grid;
  grid-template-columns: .20fr 1fr;
  align-items: center;
  margin-top: 2vw;
  
  h2{
    margin: 0 0 0 1rem;
    font-family: Poppins-Medium,sans-serif;
    font-size: 1.3vw;
    text-align: right;
    color: #686868;
  }
  
  .status-bar{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #686868;
    opacity: 1;
    margin-top: 0.1vw;
    width: 95%;
    height: 30%;
    
  }
  
  .container-message{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    img{
      opacity: 1;
      display: block;
      width: 0.9vw;
      margin-left: 0.2vw;
      padding-top: 0.1vw;
    }
    
    span{
      font-size: 0.55em;
      text-align: left;
      letter-spacing: 0px;
      color: #FF0000;
      opacity: 1;
    }
  }
  
 
  
`