import styled from "styled-components";

export const VideoComponentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  background: #FFFFFF4F 0% 0% no-repeat padding-box;
  border: 5px solid #FFFFFF;
  border-radius: 46px;
  opacity: 1;
  
  .video {
    width: 90%;
    height: 95%;
    border-radius: 46px;
  }
`