import {ButtonComponentStyles} from "./ButtonComponentStyles";

interface IButtonComponentProps {
    text: string;
    background: string;
    color: string;
    width: number;
    onClick: () => void;
}

export default function ButtonComponent({
                                            text,
                                            background,
                                            color,
                                            width,
                                            onClick,
                                        }: IButtonComponentProps): JSX.Element {

    return (
        <ButtonComponentStyles background={background} color={color} width={width}>
            <button onClick={onClick}>{text}</button>
        </ButtonComponentStyles>
    );
}
