import {CardBingoStyled} from "./CardBingoStyled";
import CircularNumber from "../CircularNumber/CircularNumber";
import {IDataNumber} from "../../../domain/models/CardBingoModel";

interface ICardBingo {
    dataNumber: IDataNumber;
    serial: string;
}

export default function CardBingo({
                                      dataNumber,
                                      serial,
                                  }: ICardBingo): JSX.Element {

    return (
        <CardBingoStyled>
            <div className="serial">{serial}</div>
            <div className="container-number-card">
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="#09BA2BBD" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.B1}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.I1}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.N1}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.G1}/>
                </div>
                <div className="border-2">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.O1}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.B2}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.I2}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.N2}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.G2}/>
                </div>
                <div className="border-2">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.O2}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.B3}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.I3}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.N3}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.G3}/>
                </div>
                <div className="border-2">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.O3}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="#09BA2BBD" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.B4}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.I4}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.N4}/>
                </div>
                <div className="border-1">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.G4}/>
                </div>
                <div className="border-2">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF5E" font_size={.8} number_selected={dataNumber.O4}/>
                </div>
                <div className="border-3">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="#09BA2BBD" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.B5}/>
                </div>
                <div className="border-3">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.I5}/>
                </div>
                <div className="border-3">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.N5}/>
                </div>
                <div className="border-3">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.G5}/>
                </div>
                <div className="border-4">
                    <CircularNumber position="unset" width={1.1} height={1.1} background="unset" border="none"
                                    color="#FFFFFF" font_size={.8} number_selected={dataNumber.O5}/>
                </div>
            </div>
        </CardBingoStyled>
    );
}