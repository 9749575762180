import {EditSessionStyles} from "./EditSessionStyles";
import SweetAlert2 from "react-sweetalert2";
import {useForm} from "react-hook-form";
import {CreateSessionModel} from "../../../domain/models/CreateSessionModel";
import {EditSessionModel} from "../../../domain/models/EditSessionModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useState} from "react";
import {editSession, getAllSessions, getSingleSession} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {cardLimit, cardPrice, getTodayDate} from "../../../shared/Utils/ValidationformCreateSession";
import {getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";

interface IEditSessionProps {
    handleCloseModal: () => void;
}

export default function EditSession({
                                        handleCloseModal,
                                    }: IEditSessionProps): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<EditSessionModel>();
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);

    function handleEditSession(data: EditSessionModel) {
        if (singleSession !== null) {
            dispatch(editSession(data, singleSession.session_id.toString())).then(r => {
                dispatch(getAllSessions());
                dispatch(getSingleSession(singleSession.session_id.toString())).then(r => {
                    alertSuccess('Sesión editada con éxito');
                    handleCloseModal();
                });
            });
        }
    }

    const onSubmit = (data: EditSessionModel) => handleEditSession(data);

    return (
        <EditSessionStyles>
            <div className="button-close-modal" onClick={handleCloseModal}>X</div>
            <h1 className="title-bingo">Editar sesión</h1>
            <form className="form-bingo" onSubmit={handleSubmit(onSubmit)}>
                {/*<div className="container-input">*/}
                {/*    <label htmlFor="new_bingo_limit">Limite de Bingo: </label>*/}
                {/*    <select*/}
                {/*        id="new_bingo_limit"*/}
                {/*        defaultValue={singleSession?.bingo_limit}*/}
                {/*        {...register("new_bingo_limit", {required: "Límite del bingo es requerido"})}*/}
                {/*        aria-invalid={errors.new_bingo_limit ? "true" : "false"}*/}
                {/*    >*/}
                {/*        <option value="25">25</option>*/}
                {/*        <option value="50">50</option>*/}
                {/*        <option value="75">75</option>*/}
                {/*        <option value="99">99</option>*/}
                {/*    </select>*/}
                {/*    {errors.new_bingo_limit?.type === 'required' &&*/}
                {/*        <span className="error" role="alert">{errors.new_bingo_limit?.message}</span>*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="container-input">
                    <label htmlFor="new_card_limit">Limite de tarjetones: </label>
                    <input
                        id="new_card_limit"
                        defaultValue={singleSession?.card_limit}
                        type="text"
                        {...register("new_card_limit", {
                            required: "el límite de tarjetones es requerido",
                            validate: cardLimit
                        })}
                        aria-invalid={errors.new_card_limit ? "true" : "false"}
                    />
                    {errors.new_card_limit?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_card_limit?.message}</span>
                    }
                    {errors.new_card_limit?.type === 'validate' &&
                        <span className="error" role="alert">{errors.new_card_limit?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="new_card_price">Valor bingo: </label>
                    <input
                        id="new_card_price"
                        defaultValue={singleSession?.card_price}
                        type="text"
                        {...register("new_card_price", {
                            required: 'El precio del bingo es requerido',
                            validate: cardPrice
                        })}
                        aria-invalid={errors.new_card_price ? "true" : "false"}
                    />
                    {errors.new_card_price?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_card_price?.message}</span>
                    }
                    {errors.new_card_price?.type === 'validate' &&
                        <span className="error" role="alert">{errors.new_card_price?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Nombre de la sesión: </label>
                    <input
                        defaultValue={singleSession?.session_name}
                        placeholder="Nombre de la sesión"
                        type="text"
                        {...register("new_session_name", {required: "El nombre de la sesión es requerido"})}
                        aria-invalid={errors.new_session_name ? "true" : "false"}
                    />
                    {errors.new_session_name?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_session_name?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Creador: </label>
                    <input
                        defaultValue={singleSession?.creator}
                        placeholder="Nombre del creador"
                        type="text"
                        {...register("new_creator", {required: "El nombre del creador es requerido"})}
                        aria-invalid={errors.new_creator ? "true" : "false"}
                    />
                    {errors.new_creator?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_creator?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="card_price">Fecha de cita: </label>
                    <input
                        defaultValue={singleSession?.scheduled_date}
                        type="date"
                        {...register("new_scheduled_date", {
                            required: "Fecha requerida",
                            validate: {
                                validDate: (value) => value >= getTodayDate() || "La fecha no puede ser menor a la fecha actual"
                            }
                        })}
                        aria-invalid={errors.new_scheduled_date ? "true" : "false"}
                    />
                    {errors.new_scheduled_date?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_scheduled_date?.message}</span>
                    }
                    {errors.new_scheduled_date && (
                        <span className="error" role="alert">{errors.new_scheduled_date?.message}</span>
                    )}
                </div>
                <button className="button-bingo" type="submit">Editar Sesion</button>
            </form>
        </EditSessionStyles>
    );
}