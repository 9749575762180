import React from 'react';
import './App.css';
import {Redirect, Route, Router} from "react-router-dom";
import Home from "./modules/user/presentation/Screen/Home/Home";
import AdminHome from "./modules/admin/presentation/Screens/Home/AdminHome";
import Test from "./modules/admin/presentation/Screens/Test/Test";
import {Provider} from "react-redux";
import {store} from "./modules/admin/store/Store";
import Login from "./modules/admin/presentation/Screens/Login/Login";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

function App() {
  return (
      <Provider store={store}>
          <Router history={history}>
              <Route path="/login" children={<Login/>}/>
              <Route path="/user" children={<Home/>}/>
              <Route path="/admin" children={<AdminHome/>}/>
              <Route path="/test" children={<Test/>}/>
              <Redirect to="login"/>
          </Router>
      </Provider>
  );
}

export default App;
