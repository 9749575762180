import styled from "styled-components";

export const FooterStyled = styled.div `
  width: 100%;
  height: 4%;
  position: absolute;
  bottom: -5vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  

  span {
    font-family: Poppins-Medium,serif;
    font-size: .8em;
    text-align: left;
    letter-spacing: 0px;
    color: #686868;
    opacity: 1;
    font-weight: bold;
    display: block;
    margin-right: .5rem;
    
  }




`