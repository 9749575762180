import {CreateBingoStyles} from "./CreateBingoStyles";
import {gameModes} from "../../../shared/Utils/GameModes";
import {useForm} from "react-hook-form";
import {CreateBingoFormModel, CreateBingoModel} from "../../../domain/models/CreateBingoModel";
import {createBingo, getAllBingos} from "../../../store/ActionsAdmin/BingosActions";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {BingoHeader} from "../../../shared/Utils/BingoHeader";
import {winnerNumber} from "../../../shared/Utils/ValidationformCreateBingo";

interface ICreateBingoProps {
    handleCloseModal: () => void;
}

export default function CreateBingo({
                                        handleCloseModal,
                                    }: ICreateBingoProps): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateBingoFormModel>();
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const loadingCreateBingo = useAppSelector((state) => state.bingoState.loadingCreateBingo);

    function handleCreateBingo(data: CreateBingoFormModel) {
        if (singleSession !== null) {
            let reqData: CreateBingoModel = {
                'create_game': 'ok',
                'game_mode': data.game_mode,
                'prize': data.prize,
                // 'bingo_header': data.bingo_header,
                // 'name_table_users': 'users',
                // 'bingo_limit': singleSession.bingo_limit.toString(),
                'session_id': singleSession.session_id.toString(),
                'buy_status': '1',
                'bingo_name': data.bingo_name,
                'winners_number': data.winners_number
            }
            dispatch(createBingo(reqData)).then(r => {
                reset({
                    bingo_name: "",
                    prize: "",
                    game_mode: "",
                    winners_number: "",
                });
                dispatch(getAllBingos(singleSession.session_id.toString()));
                alertSuccess('Bingo creado con éxito...');
                handleCloseModal();
            });
        }
    }

    const onSubmit = (data: CreateBingoFormModel) => handleCreateBingo(data);

    return (
        <CreateBingoStyles>
            <div className="button-close-modal" onClick={handleCloseModal}>X</div>
            <h1 className="title-bingo">Crear bingo</h1>
            <h5 className="session-name">Nombre sesión: {singleSession?.session_name}</h5>
            <form className="form-bingo" onSubmit={handleSubmit(onSubmit)}>
                {/*<div className="container-input">*/}
                {/*    <label htmlFor="bingo_header">Cabecera de juego: </label>*/}
                {/*    <select*/}
                {/*        {...register("bingo_header", {required: "Cabecera de juego requerido"})}*/}
                {/*        aria-invalid={errors.bingo_header ? "true" : "false"}*/}
                {/*    >*/}
                {/*        <option className="placeholder-option" value="">--Selecciona la cabecera del juego--</option>*/}
                {/*        {BingoHeader.map((value, index) => (*/}
                {/*            <option key={index} value={value}>{value}</option>*/}
                {/*        ))}*/}
                {/*    </select>*/}
                {/*    {errors.bingo_header?.type === 'required' &&*/}
                {/*        <span className="error" role="alert">{errors.bingo_header?.message}</span>*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="container-input">
                    <label htmlFor="name">Nombre: </label>
                    <input
                        placeholder="Nombre del bingo"
                        type="text"
                        {...register("bingo_name", {required: "El nombre del bingo es requerido"})}
                        aria-invalid={errors.bingo_name ? "true" : "false"}
                    />
                    {errors.bingo_name?.type === 'required' &&
                        <span className="error" role="alert">{errors.bingo_name?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="email">Premio: </label>
                    <input
                        placeholder="Premio del bingo"
                        type="text"
                        {...register("prize", {required: "el premio es requerido"})}
                        aria-invalid={errors.prize ? "true" : "false"}
                    />
                    {errors.prize?.type === 'required' &&
                        <span className="error" role="alert">{errors.prize?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="email">Límite ganadores: </label>
                    <input
                        placeholder="Límite de ganadores"
                        type="text"
                        {...register("winners_number", {
                            required: "el limite de ganadores es requerido",
                            validate: winnerNumber
                        })}
                        aria-invalid={errors.winners_number ? "true" : "false"}
                    />
                    {errors.winners_number?.type === 'required' &&
                        <span className="error" role="alert">{errors.winners_number?.message}</span>
                    }
                    {errors.winners_number?.type === 'validate' &&
                        <span className="error" role="alert">{errors.winners_number?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="game_mode">Modo de juego: </label>
                    <select
                        {...register("game_mode", {required: "Modo de juego requerido"})}
                        aria-invalid={errors.game_mode ? "true" : "false"}
                    >
                        <option className="placeholder-option" value="">--Selecciona el modo de juego--</option>
                        {gameModes.map((value, index) => (
                            <option key={index} value={value.ref}>{value.translationRef}</option>
                        ))}
                    </select>
                    {errors.game_mode?.type === 'required' &&
                        <span className="error" role="alert">{errors.game_mode?.message}</span>
                    }
                </div>
                <button className="button-bingo" disabled={loadingCreateBingo} type="submit">{!loadingCreateBingo ? "Crear juego" : "Creando juego..."}</button>
            </form>
        </CreateBingoStyles>
    );
}