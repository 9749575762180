import {FooterStyled} from "./FooterStyled";

export default function Footer():JSX.Element {

    return (
        <FooterStyled>
            <div className="container-social-networks">
                <a>
                    <img alt="twitter" className="social-network" src="/icon/telegram_big.png"/>
                </a>
                <a>
                    <img alt="whatsApp" className="social-network" src="/icon/whatsApp_icon.svg"/>
                </a>
            </div>
            <div className="link-info">
                <a>Términos y cóndiciones</a>
                <a>Reglas del Bingo</a>
            </div>
        </FooterStyled>
    );
}