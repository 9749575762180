import styled from "styled-components";

export const CircularAvatarStyled = styled.div`
  background: white;
  width: 5.8vw;
  height: 5.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  
  .avatar {
    width: 5.4vw;
    height: 5.4vw;
  }
`