import {launchBallot, startGame} from "../../../store/ActionsAdmin/BingosActions";

export const createBingoTesting:string = 'https://ryj2qxfqkukaniasknpnwa7wau0ooilj.lambda-url.us-east-1.on.aws/';
export const getAllBingosTesting:string = 'https://t4irppvxpbufael4n5rey7hxlm0mkysu.lambda-url.us-east-1.on.aws/';
export const getSingleBingosTesting:string = 'https://t4irppvxpbufael4n5rey7hxlm0mkysu.lambda-url.us-east-1.on.aws/';
export const launchBallotTesting:string = 'https://hmje35ilz6xayvkzrw3woukkuu0xsfxl.lambda-url.us-east-1.on.aws/';
export const startGameTesting:string = 'https://psefukwub2zb76uikgqjtqvlsi0rcxps.lambda-url.us-east-1.on.aws/';
export const editBingoTesting:string = 'https://n3oe5665bvxzau6pezuau2lr5q0qqmvv.lambda-url.us-east-1.on.aws/';










// sessions
export const createSessionTesting:string = 'https://wkvap453hr33a67zq5v5w6hm7y0jrjhq.lambda-url.us-east-1.on.aws/';
export const editSessionTesting:string = 'https://wkvap453hr33a67zq5v5w6hm7y0jrjhq.lambda-url.us-east-1.on.aws/';
export const singleSessionTesting:string = 'https://wkvap453hr33a67zq5v5w6hm7y0jrjhq.lambda-url.us-east-1.on.aws/';