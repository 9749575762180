import styled from "styled-components";

export const SecondarySidebarStyles = styled.div`
  width: 18vw;
  padding: 0.1rem 0 .5rem .1rem;

  .title-sidebar-secondary {
    font-family: Poppins-Bold, serif;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    font-size: 1em;
    padding: 1.5rem 1.5rem 0 0.5rem;
    opacity: 1;
  }

  .container-info-session {
    margin: .1rem 1rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .info-session {
      line-height: .8rem;

      .info {
        .text-bold {
          font-family: Poppins-Medium, serif;
          font-size: 0.75em;
          font-weight: bold;
          text-align: left;
          letter-spacing: 0px;
          color: #333333;
        }

        .text {
          font-family: Poppins-LightItalic, serif;
          font-size: 0.75em;
          text-align: left;
          letter-spacing: 0px;
          color: #333333;
        }
      }
    }
  }

  .container-list {
    margin: 1.1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 0.9985703825950623px solid #A4A4A4;
    border-radius: 6px;
    opacity: 1;
    overflow-y: scroll;
    direction: rtl;
    height: 9.5rem;

    ul {
      list-style: none;
      padding-inline-start: 0.6em;
      margin-block-start: .5rem;
      margin-block-end: 0;
      direction: ltr;

      .list-background {
        background: #CDCDCD;
        color: #000;
        font-size: .70em;
      }
      li {
        display: flex;
        align-items: center;
        font-family: Poppins-Medium, serif;
        font-size: 0.60em;
        text-align: left;
        letter-spacing: 0px;
        color: #686868;
        opacity: 1;
        gap: 0.3rem;
        margin-bottom: .5rem;
        cursor: pointer;


        .circle {
          width: 1vw;
        }

      }
    }


  }

  .container-transmission {
    margin: .5rem 0 0;
    padding-left: 1rem;
    text-align: left;
    letter-spacing: 0px;
    color: #686868;
    opacity: 1;
    font-size: 1.45em;


    .link-transmission {
      display: flex;
      padding-left: 0.5rem;
      position: relative;
      z-index: 3;

      input {
        padding-left: 0.4rem;
        width: 10.3vw;
        margin-bottom: 0rem;
        border: 1px solid #E4E4E4;
        border-radius: 3px;
        opacity: 1;
        outline: none;
      }

    }

  }


`