import {ContentStyles} from "./ContentStyles";
import Accumulated from "../Accumulated/Accumulated";
import SessionInfo from "../SessionInfo/SessionInfo";
import Numbers from "../Numbers/Numbers";
import LastBallots from "../LastBallots/LastBallots";
import NewBallots from "../NewBallots/NewBallots";
import ServerStatus from "../ServerStatus/ServerStatus";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";

interface IContentProps {
    handleOpenModal: (type: TypeActionModal) => void;
}

export default function Content({
                                    handleOpenModal,
                                }: IContentProps): JSX.Element {

    return (
        <ContentStyles>
            {/*componente1 */}
            <Accumulated/>
            {/*componente2 */}
            <SessionInfo handleOpenModal={handleOpenModal}/>
            {/*componente3 */}
            <Numbers/>
            {/*componente4 */}
            <LastBallots/>
            {/*componente5 */}
            <NewBallots handleOpenModal={handleOpenModal}/>
            {/*componente6 */}
            <ServerStatus/>
        </ContentStyles>
    );
}