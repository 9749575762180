import styled from "styled-components";

export const InfoPlayStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  height: 24vh;
  margin: 1rem 1rem 0;
  
  .text-1 {
    font-family: CoolveticaRg-Regular,serif;
    width: 100%;
    font-size: 1.3vw;
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: .5rem;
  }
  .text-2 {
    font-family: Anton-Regular,serif;
    width: 100%;
    font-size: .87vw;
    text-align: left;
    letter-spacing: 0;
    color: #E09500;
    opacity: 1;
  }
  .text-3 {
    font-family: Montserrat-Regular,serif;
    font-size: 1.7vw;
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
  }
  .bingoL {
    width: 6vw;
    opacity: 0.36;
  }
`