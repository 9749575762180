import styled from "styled-components";

export const AccumulatedStyles = styled.div`
  background: white;
  width: 100%;
  height: 10%;
  //align-self: center;
  margin: auto;

  .p-accumulated{
    margin-left: 1%;
    font-family: Poppins-Medium;
    font-size: 13px;
    text-align: left;
    letter-spacing: 0px;
    color: #686868;
    opacity: 1;
  }

  .rectangle{
    width: 308px; 
    height: 2vh;
    background: white 0% 0% no-repeat padding-box;    
    border: 0.20348608493804932px solid #E4E4E4;
    opacity: 1;
    border-radius: 7px;
    padding: 0;
    font_family: Poppins-LightItalic;
    font-size: 10.37px;
    text-align: right;
    letter-spacing: 0px;
    color: #333333;
  }

  .rectangle2{
    width: 231px; 
    height: 2vh;
    background: #CDCDCD 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 7px;
    border-right: 60px solid transparent;
  }

  .rectangle-text{
    font-family: Poppins-LightItalic;
    font-style: italic;
    font-size: 10.37px;
    margin-right: -32%;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .p2-accumulated{
    font-family: Poppins-LightItalic;
    font-style: italic;
    font-size: 12.46px;
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
`
