import {AppSidebarStyles} from "./AppSidebarStyles";
import {useEffect, useState} from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    cleanAllBingos,
    cleanSingleBingo,
    getAllBingos,
    getSingleBingo
} from "../../../store/ActionsAdmin/BingosActions";
import {
    cleanSingleSession,
    createSession,
    deleteSession,
    getAllSessions,
    getSingleSession
} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";

type dropdownType = "perfil" | "configuracion" | "sesiones";

interface IAppSidebar {
    handleOpenModal: (type: TypeActionModal) => void;
}

export default function AppSidebar({
                                       handleOpenModal,
                                   }: IAppSidebar): JSX.Element {

    const [typeDropdown, setTypeDropdown] = useState<dropdownType>("sesiones");
    const [listActive, setListActive] = useState<number>(0);


    const allSessions = useAppSelector((state) => state.sessionState.allSessions);
    const loadingAllSessions = useAppSelector((state) => state.sessionState.loadingAllSessions);
    const dispatch = useAppDispatch();

    function dropdownActive(data: dropdownType) {
        if (data === "perfil") {
            setTypeDropdown(data);
        }
        if (data === "configuracion") {
            setTypeDropdown(data);
        }
        if (data === "sesiones") {
            setTypeDropdown(data);
        }
    }

    function showListActive(session_id: number) {
        setListActive(session_id);
        dispatch(cleanSingleBingo());
        dispatch(getAllBingos(session_id.toString()));
        dispatch(getSingleSession(session_id.toString()));
    }

    function newSessionClick() {
        handleOpenModal('create_session');
    }
    function handleEdit() {
        handleOpenModal('edit_session');
    }

    function handleDeleteSession(session_id: number) {
        dispatch(deleteSession(session_id.toString())).then(r => {
            dispatch(cleanSingleBingo());
            dispatch(cleanAllBingos());
            dispatch(cleanSingleSession());
            dispatch(getAllSessions());
            alertSuccess('Sesión eliminada con éxito...');
        });
    }

    useEffect(() => {
        dispatch(getAllSessions());
    }, []);

    return (
        <AppSidebarStyles>
            <div className="title-sidebar">La Balota Online</div>
            <div className="container-links">
                <div className="button-dropdown" onClick={() => dropdownActive("perfil")}>
                    <div className={typeDropdown === "perfil" ? "dropdown-active" : "div-dropdown"}>
                        <img src="/icon/iconAdmin/icon-profile.svg"/>
                        <span>PERFIL</span>
                    </div>
                    {typeDropdown === "perfil" && (
                        <ul>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                        </ul>
                    )}
                </div>
                <div className="button-dropdown" onClick={() => dropdownActive("configuracion")}>
                    <div className={typeDropdown === "configuracion" ? "dropdown-active" : "div-dropdown"}>
                        <img src="/icon/iconAdmin/icon-config.svg"/>
                        <span>CONFIGURACIÓN</span>
                    </div>
                    {typeDropdown === "configuracion" && (
                        <ul>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                            <li className="list">DATO</li>
                        </ul>
                    )}
                </div>
                <div className="button-dropdown" onClick={() => dropdownActive("sesiones")}>
                    <div className={typeDropdown === "sesiones" ? "dropdown-active" : "div-dropdown"}>
                        <img src="/icon/iconAdmin/icon-sesion.svg"/>
                        <span>SESIONES</span>
                    </div>
                    {typeDropdown === "sesiones" && (
                        <ul>
                            {!loadingAllSessions && (
                                allSessions.map((session) => (
                                    <li key={session.session_id}
                                        className={listActive === session.session_id ? 'listActive' : 'list'}
                                        onClick={() => showListActive(session.session_id)}>
                                        {session.session_name}
                                        {listActive === session.session_id && (
                                            <span>
                                            <img
                                                onClick={handleEdit}
                                                alt="editar"
                                                src="/icon/iconAdmin/editar-icon.svg"
                                            />
                                            <img
                                                onClick={() => handleDeleteSession(session.session_id)}
                                                alt="delete"
                                                src="/icon/iconAdmin/delete-icon.png"
                                            />
                                        </span>
                                        )}
                                    </li>))
                            )}
                            {loadingAllSessions && (
                                <div className="info-sessions">

                                </div>
                            )}
                        </ul>
                    )}
                </div>
            </div>
            <ButtonComponent
                text="Nueva sesión"
                background="#88A90F 0% 0% no-repeat padding-box"
                color="#FFFFFF"
                width={80}
                onClick={newSessionClick}
            />
        </AppSidebarStyles>
    );
}