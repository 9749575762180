import {LastBallotsStyles} from "./LastBallotsStyles";
import {ListAllBingosModel} from "../../../domain/models/ListAllBingosModel";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../shared/Utils/Hooks";



export default function LastBallots(): JSX.Element {

    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const [scrollOffset, setScrollOffset] = useState<number>(0);

    function handleClickScroll(data_direction: string) {
        if (data_direction === 'left') {
            // Mueve el scroll hacia la izquierda, reduciendo el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset - 100);
        }
        if (data_direction === 'right') {
            // Mueve el scroll hacia la derecha, aumentando el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset + 100);
        }
    }

    return (
        <LastBallotsStyles>
            <h2>Últimas Balotas: </h2>
            <div className="container-bar">
                <div className="triangle-box" onClick={() => handleClickScroll('left')}>
                    <span className="triangle"></span>
                </div>

                <div className="triangle-box-right">
                    <div
                        className="numbers-container"
                        style={{ transform: `translateX(-${scrollOffset}px)` }}
                    >
                        {singleBingo &&
                            singleBingo.ballots?.map((ballot,  index) => (
                                <div key={index} className="numbers">
                                    {ballot[2]}-
                                </div>
                            ))}
                    </div>
                </div>

                <div className="triangle-box" onClick={() => handleClickScroll('right')}>
                    <span className="triangle-right"></span>
                </div>
            </div>
        </LastBallotsStyles>
    );
}