import styled from "styled-components";

export const ProgressBarStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;

  .linearProgress {
    width: 100%;
    height: 3.4vw;
    background-color: #757575;
    border: 5px solid #FFFFFF;
    border-radius: 35px;
    opacity: 1;
  }
  .css-5xe99f-MuiLinearProgress-bar1 {
    background: rgb(20,128,57);
    background: linear-gradient(90deg, rgba(20,128,57,1) 0%, rgba(74,221,128,1) 50%, rgba(15,59,26,1) 100%);
  }
  .soccer-ball {
    position: absolute;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    width: 5vw;
    left: -4vw;
  }
  .container-soccer-goal {
    position: absolute;
    right: -1vw;
    
    .container-soccer-goal-text {
      position: relative;
      bottom: 0.5vw;

      .soccer-goal {
        width: 9vw;
      }
      .soccer-goal-text {
        position: absolute;
        margin: 0;
        top: 6vw;
        width: 5vw;
        text-align: center;
        letter-spacing: 0;
        line-height: 1em;
        color: #FFFFFF;
        opacity: 1;
        font-size: .7vw;
        left: 1.8vw;
      }
    }
  }
`