import {ComponentGamersStyled} from "./ComponentGamersStyled";
import DataGamers from "../DataGamers/DataGamers";

export default function ComponentGamers():JSX.Element {

    return (
        <ComponentGamersStyled>
            <DataGamers imgAvatar="/img/avatar_header_small.png" text="#1 13 VICTORIAS" name="JUAN"/>
            <DataGamers imgAvatar="/img/avatar_header_small.png" text="#2 13 VICTORIAS" name="LUIS"/>
            <DataGamers imgAvatar="/img/avatar_header_small.png" text="#3 11 VICTORIAS" name="ANDREA"/>
            <DataGamers imgAvatar="/img/avatar_header_small.png" text="#4 9 VICTORIAS" name="VICKY"/>
            <DataGamers imgAvatar="/img/avatar_header_small.png" text="#5 6 VICTORIAS" name="OSCAR"/>
        </ComponentGamersStyled>
    );
}