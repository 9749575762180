import styled from "styled-components";

interface IButtonComponentStylesProps{
    background: string;
    color: string;
    width: number;
}
export const ButtonComponentStyles = styled.div<IButtonComponentStylesProps>`

  button{
    display: block;
    margin: auto;
    padding: .3rem 0;
    background: ${({background})=>background};
    opacity: 1;
    color: ${({color})=>color};
    border: none;
    cursor: pointer;
    font-family: Poppins-Italic,serif;
    font-size: .84em;
    width: ${({width})=>width+"%"};
  }


`