import {ShowWinnersStyles} from "./ShowWinnersStyles";
import {useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useState} from "react";

interface IShowWinnersProps {
    handleCloseModal: () => void;
}

export default function ShowWinners({
                                        handleCloseModal,
                                    }: IShowWinnersProps): JSX.Element {

    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const [winners, setWinners] = useState<any[]>([]);
    const [countWinners, setCountWinners] = useState<number>(0);


    function dataWinners() {
        console.log(typeof singleBingo?.winners);
        if (singleBingo && singleBingo?.winners !== null) {
            if (typeof singleBingo.winners === "string" && singleBingo.winners != "") {
                const dataObject = JSON.parse(singleBingo.winners);
                console.log(dataObject);
                const winnerArray = Object.values(dataObject);
                console.log(winnerArray);
                const winnersData = singleBingo.winners_number;
                setWinners(winnerArray);
                setCountWinners(winnersData);
            }
        }
    }

    useEffect(() => {
        dataWinners();
    }, []);

    return (
        <ShowWinnersStyles>
            <div className="button-close-modal" onClick={handleCloseModal}>X</div>
            <h1>Ganadores</h1>
            {winners.length > 0 && winners.map((winner, index) => (
                <div
                    className="container-winner"
                    key={index}
                    style={{color: index < countWinners ? 'green' : 'red'}}
                >
                    <span>{index + 1}</span>
                    <span>Jugador: {winner.name}</span>
                    <span>Puntaje: {winner.total_score}</span>
                </div>
            ))}
            {winners.length == 0 && <h3>Aun no hay ganadores</h3>}
        </ShowWinnersStyles>
    );
}