import styled from "styled-components";

export const FooterStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 10%;
  
  .container-social-networks {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding-left: 1rem;
    
    a {
      
      .social-network {
        width: 3vw;
        cursor: pointer;
      }
    }
  }
  .link-info {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.5rem;
    padding-right: 4rem;
    
    a {
      font-family: CoolveticaRg-Regular,serif;
      font-size: 1.5em;
      text-align: left;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      text-decoration: none;
      cursor: pointer;
    }
  }
`