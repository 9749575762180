import {CHANGE_SELECTED_OPTIONS, UPDATE_SELECTED_OPTIONS} from "../TypesAdmin/SelectedOptionTypes";

interface IStateSelectedOption {
    selectedOption:string
}
const initialStateSelectedOption:IStateSelectedOption = {
    selectedOption: 'semi'
}
const selectedOptionReducer = function (state = initialStateSelectedOption, action: { type:any; payload:any }): IStateSelectedOption {
    switch (action.type) {
        case CHANGE_SELECTED_OPTIONS:
            return {
                selectedOption:action.payload
            };
        case UPDATE_SELECTED_OPTIONS:
            return {
                selectedOption: action.payload
            };
        default:
            return state;
    }
}
export default selectedOptionReducer;