import styled from "styled-components";

export const DataGamersStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF73 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 100%;
  height: 10%;
  
  .container-avatar-gamer {
    position: absolute;
    border-radius: 100%;
    background: #707070;
    opacity: 1;
    width: 3.5vw;
    height: 3.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -.3em;

    .avatar-gamer {
      height: 3vw;
    }
    .soccer-ball-avatar {
      position: absolute;
      height: 1.2vw;
      left: 2.2vw;
      top: 2.5vw;
    }
  }
  .container-text-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 3vw;
    font-family: CoolveticaRg-Regular,serif;
    
    .text {
      width: 100%;
      text-align: left;
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
      font-size: .8vw;
    }
    .name {
      width: 100%;
      text-align: left;
      letter-spacing: 0;
      color: #001826;
      opacity: 1;
      font-size: 1.1vw;
      font-weight: bold;
    }
  }
`