import styled from "styled-components";

export const SwitchButtonStyled = styled.div`
  
  .container-switch-button-off {
    position: relative;
    width: 3vw;
    height: 2vh;
    border: 1px solid #bcbcbd;
    background: #e2e2e2;
    border-radius: 25px;
    margin: 0 0 .1rem .5rem;
    cursor: pointer;

    .button-switch-off {
      width: 1vw;
      height: 1.8vh;
      border: 1px solid #bcbcbd;
      border-radius: 100%;
      background: #f1f1ef;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      transition: left 1s ease;

      img {
        width: 8px;
      }
    }
  }
  .container-switch-button-on {
    position: relative;
    width: 3vw;
    height: 2vh;
    border: 1px solid #1bcc4a;
    background: #00e561;
    border-radius: 25px;
    margin: 0 0 .1rem .5rem;
    cursor: pointer;

    .button-switch-on {
      width: 1vw;
      height: 1.8vh;
      border: 1px solid #1bcc4a;
      border-radius: 100%;
      background: #f1f1ef;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      transition: right 1s ease;

      img {
        width: 8px;
      }
    }
  }
`