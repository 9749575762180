import {AdminHomeStyles} from "./AdminHomeStyles";
import {AppHeader} from "../../Components/Header/AppHeader";
import {Footer} from "../../Components/Footer/Footer";
import {AppSidebar} from "../../Components";
import Content from "../../Components/Content/Content";
import {useEffect, useState} from "react";
import SecondarySidebar from "../../Components/SecondarySidebar/SecondarySidebar";
import {useAppSelector} from "../../../shared/Utils/Hooks";
import CreateSession from "../../Components/CreateSession/CreateSession";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";
import EditSession from "../../Components/EditSession/EditSession";
import CreateBingo from "../../Components/CreateBingo/CreateBingo";
import EditBingo from "../../Components/EditBingo/EditBingo";
import ShowWinners from "../../Components/ShowWinners/ShowWinners";
import {secret} from "../../../shared/Utils/constants";
import {history} from "../../../../../App";


export default function AdminHome(): JSX.Element {

    const [openSecondarySidebar, setOpenSecondarySidebar] = useState<boolean>(true)
    const singleBingoInitial = useAppSelector((state) => state.bingoState.singleBingo);
    const loadingSingleBingo = useAppSelector((state) => state.bingoState.loadingSingleBingo);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [typeActionModal, setTypeActionModal] = useState<TypeActionModal>('');
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const loadingSingleSession = useAppSelector((state) => state.sessionState.loadingSingleSession);

    // function openSidebar() {
    //     setOpenSecondarySidebar(true);
    // }

    // function hideSidebar() {
    //     setOpenSecondarySidebar(false);
    // }

    function handleCloseModal() {
        setShowModal(false);
        setTypeActionModal('');
    }
    function handleOpenModal(type:TypeActionModal) {
        setShowModal(true);
        setTypeActionModal(type);
    }
    function handleOpenModalWinners() {
        if (singleBingoInitial && singleBingoInitial.end_time !== '') {
            setShowModal(true);
            setTypeActionModal('winners');
        }
    }

    useEffect(() => {

        let localuser = sessionStorage.getItem("user_secret");
        if(!localuser || localuser != secret){
            sessionStorage.removeItem("user_secret");
            history.push("/login")
        }

    }, []);

    useEffect(() => {
        handleOpenModalWinners();
    }, []);

    return (
        <AdminHomeStyles>

            <AppHeader/>
            {/*este rellena el 40% de la pantalla*/}
            <AppSidebar
                handleOpenModal={handleOpenModal}
            />
            {singleSession && !loadingSingleSession && (
                <SecondarySidebar handleOpenModal={handleOpenModal}/>
            )}
            {!singleSession && !loadingSingleSession && (
                <div className="selectSession">Seleccione una sesión...</div>
            )}
            {!singleSession && loadingSingleSession && (
                <div className="selectSession">Cargando sesión...</div>
            )}
            {/*este rellena el 60% de la pantalla*/}
            {singleBingoInitial && <Content handleOpenModal={handleOpenModal}/>}
            {!singleBingoInitial && <span>Seleccione un bingo...</span>}
            {/*{!singleBingoInitial && loadingSingleBingo && <span>Cargando bingo...</span>}*/}

            <Footer/>

            {showModal && (
                <div className="modal">
                    {/*<div className="button-close-modal" onClick={handleCloseModal}>X</div>*/}
                    {typeActionModal === 'create_session' && (
                        <CreateSession handleCloseModal={handleCloseModal}/>
                    )}
                    {typeActionModal === 'edit_session' && (
                        <EditSession handleCloseModal={handleCloseModal}/>
                    )}
                    {typeActionModal === 'create_bingo' && (
                        <CreateBingo handleCloseModal={handleCloseModal}/>
                    )}
                    {typeActionModal === 'edit_bingo' && (
                        <EditBingo handleCloseModal={handleCloseModal}/>
                    )}
                    {typeActionModal === 'winners' && (
                        <ShowWinners handleCloseModal={handleCloseModal}/>
                    )}
                </div>
            )}

        </AdminHomeStyles>
    );
}