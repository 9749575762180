import {EditBingoStyles} from "./EditBingoStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {gameModes} from "../../../shared/Utils/GameModes";
import {EditBingoModel} from "../../../domain/models/EditBingoModel";
import {editBingo, getAllBingos, getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";

interface IEditSessionProps {
    handleCloseModal: () => void;
}

export default function EditBingo({
                                        handleCloseModal,
                                    }: IEditSessionProps): JSX.Element {

    const dispatch = useAppDispatch();
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const loadingEditBingo = useAppSelector((state) => state.bingoState.loadingEditBingo);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const { register, handleSubmit,reset , formState:{errors} } = useForm<EditBingoModel>();


    function handleEditBingo(data: any) {
        if (singleBingo && singleSession) {
            let editBingoData:EditBingoModel = {
                'new_game_mode': data.new_game_mode,
                'new_bingo_name': data.new_bingo_name,
                'new_prize': data.new_prize,
                'game_id': singleBingo.game_id.toString()
            }
            dispatch(editBingo(editBingoData)).then(r => {
                dispatch(getAllBingos(singleSession.session_id.toString()));
                dispatch(getSingleBingo(singleBingo.game_id.toString())).then(r => {
                    handleCloseModal();
                });
            });
        }
    }

    const onSubmit = (data: any) => handleEditBingo(data);

    return (
        <EditBingoStyles>
            <div className="button-close-modal" onClick={handleCloseModal}>X</div>
            <h1 className="title-bingo">Editar Bingo</h1>
            <h5 className="session-name">Nombre sesión: {singleSession?.session_name}</h5>
            <form className="form-bingo" onSubmit={handleSubmit(onSubmit)}>
                <div className="container-input">
                    <label htmlFor="name">Nombre: </label>
                    <input
                        defaultValue={singleBingo?.bingo_name}
                        type="text"
                        {...register("new_bingo_name", { required: 'El nombre del bingo es requerido' })}
                        aria-invalid={errors.new_bingo_name ? "true" : "false"}
                    />
                    {errors.new_bingo_name?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_bingo_name?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="email">Premio: </label>
                    <input
                        defaultValue={singleBingo?.prize}
                        type="text"
                        {...register("new_prize", { required: 'el premio es requerido' })}
                        aria-invalid={errors.new_prize ? "true" : "false"}
                    />
                    {errors.new_prize?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_prize?.message}</span>
                    }
                </div>
                <div className="container-input">
                    <label htmlFor="game_mode">Modo de juego: </label>
                    <select
                        {...register("new_game_mode", { required: "Modo de juego requerido" })}
                        aria-invalid={errors.new_game_mode ? "true" : "false"}
                    >
                        {gameModes.map((value, index) => (
                            <option key={index} value={value.ref}>{value.translationRef}</option>
                        ))}
                    </select>
                    {errors.new_game_mode?.type === 'required' &&
                        <span className="error" role="alert">{errors.new_game_mode?.message}</span>
                    }
                </div>
                <button className="button-bingo" type="submit">Editar juego</button>
            </form>
        </EditBingoStyles>
    );
}