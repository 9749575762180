import { LoginStyles } from "./LoginStyles";
import { Box, Button, Checkbox, colors, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CustomInput from "../../Components/CustomInput/CustomInput";
import {useEffect, useState} from "react";
import {defaultLogin, secret} from "../../../shared/Utils/constants";
import {history} from "../../../../../App";

export default function Login(): JSX.Element {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {

        let localuser = sessionStorage.getItem("user_secret");
        if(localuser === secret){
            setError(false);
            history.push("/admin")
        }

    }, []);

    function handleLogin() {
        if(login === defaultLogin && password === secret){
            sessionStorage.setItem("user_secret", secret);
            setError(false);
            history.push("/admin")
        }else{
            setError(true);
        }
    }

    function handleChangeLogin(e: any) {
        // console.log(e.target.value);
        setLogin(e.target.value);
    }

    function handleChangePassword(e: any) {
        setPassword(e.target.value);
    }

    return (
        <LoginStyles>
            <Grid
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                minHeight={550}
                sx={{
                    boxShadow: {
                        xs: "",
                        sm: "",
                        md: "15px 2px 5px -5px rgba(0,28,56, 0.2)",
                        lg: "15px 2px 5px -5px rgba(0,28,56, 0.2)",
                        xl: "15px 2px 5px -5px rgba(0,28,56, 0.2)",
                    },
                    width: {
                        xs: "",
                        sm: "",
                        md: "20vw",
                        lg: "22vw",
                        xl: "23vw ",
                    },
                    background: "#030d2e",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "rgba(0, 24, 57, 0.2)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100vh",

                    }}
                >
                    <Box width="80%">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {/* LOGO */}
                            <Box
                                sx={{
                                    mt: "60px",
                                    width: "50px",
                                    height: "50px",
                                    bgcolor: "primary.main",
                                    borderRadius: "12px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: `0 0 20px ${colors.blue[100]}`,
                                }}
                            >
                                <img src="https://cdn.balonlatino.net/wp-content/uploads/2022/04/WPlay-Colombia.png" className="logo_wplay" alt="WPLAY"/>
                            </Box>
                            {/* LOGO END */}

                            <Typography color="white" fontWeight="bold" mt={7} mb={3}>
                                Inicia sesión para jugar
                            </Typography>
                        </Box>

                        {/* INPUTS */}
                        <CustomInput
                            label="Login"
                            placeholder="Enter your login..."
                            isIconActive={false}
                            onChange={handleChangeLogin}
                        />
                        <CustomInput
                            label="Password"
                            placeholder="Enter your password..."
                            isIconActive={true}
                            onChange={handleChangePassword}
                        />
                        {/* INPUT END */}

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            mt={2}
                            width="100%"
                            color="white"
                        >
                            {/*<div style={{ display: "flex" }}>*/}
                            {/*    <Checkbox disableRipple sx={{ p: 0, pr: 1 }} />*/}
                            {/*    <Typography>Remember me</Typography>*/}
                            {/*</div>*/}
                            {/*<a*/}
                            {/*    href="#yoyo"*/}
                            {/*    style={{*/}
                            {/*        color: colors.green[500],*/}
                            {/*        textDecoration: "none",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Forget password?*/}
                            {/*</a>*/}
                            {error && <span className="error_msg">Credenciales invalidas</span>}
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 4, boxShadow: `0 0 20px ${colors.green[500]}` }}
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Grid>

        </LoginStyles>
    );
}