import styled from "styled-components";

export const AppSidebarStyles = styled.div`
  width:15.7vw;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  opacity: 1;
  
  .title-sidebar{
    font-family: Poppins-Medium,serif;
    text-align: left;
    letter-spacing: 0px;
    color: #3A3D3F;
    opacity: 1;
    padding: .3rem 0 .5rem .4rem;
    margin-bottom: 1rem;
  }
  
  .container-links{
    
    
    .button-dropdown{
      margin-bottom: 1rem;
      cursor: pointer;
      
      .dropdown-active{
        display: flex;
        align-items: center;
        justify-content: start;
        gap: .5rem;
        background: #878787 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: .3rem 0 .4rem 1rem;
        font-family: Poppins-Medium,serif;
        font-size: .6em;
        font-weight: bold;
        color: #FFFFFF;

      }
      .div-dropdown{
        display: flex;
        align-items: center;
        justify-content: start;
        gap: .5rem;
        padding: .3rem 0 .4rem 1rem;
        font-family: Poppins-Medium,serif;
        font-size: .6em;
        font-weight: bold;
        color: #333333;
        
        img{
          width: 1.1rem;
        }
      }
      ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        list-style: none;
        background: #ffffff;
        
        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: .3rem 0 .4rem 1rem;
          text-align: left;
          letter-spacing: 0;
          color: #838383;
          opacity: 1;
          font-size: .6em;
          cursor: pointer;
          background: #E4E4E4 0% 0% no-repeat padding-box;
          
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1vw;
            margin-right: 2vw;
            
            img {
              width: 1vw;
              cursor: pointer;
            }
          }
        }
        .listActive{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: .3rem 0 .4rem 1rem;
          text-align: left;
          letter-spacing: 0;
          color: #333333;
          opacity: 1;
          font-size: .75em;
          font-weight: bold;
          cursor: pointer;
          background: #E4E4E4 0% 0% no-repeat padding-box;
          clip-path: polygon(100% 0%, 93% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
          
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1vw;
            margin-right: 2vw;
            
            img {
              width: 1vw;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  
  .container-button{
    padding: .3rem 1.3rem;
    
    button{
      display: block;
      width: 100%;
      padding: .3rem 0;
      background: #88A90F 0% 0% no-repeat padding-box;
      opacity: 1;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
      font-family: Poppins-Italic,serif;
      font-size: .84em;
    }
  }
`