import {NewBallotsStyles} from "./NewBallotsStyles";
import {ListAllBingosModel} from "../../../domain/models/ListAllBingosModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {getAllBingos, getSingleBingo, launchBallot, startGame} from "../../../store/ActionsAdmin/BingosActions";
import {useEffect, useRef, useState} from "react";
import {changeSelectedOptions, updateSelectedOptions} from "../../../store/ActionsAdmin/SelectedOptionActions";
import {useForm} from "react-hook-form";
import {LaunchBallotModel} from "../../../domain/models/LaunchBallotModel";
import {alertError} from "../../../shared/Utils/AlertError";
import CircularNumber from "../CircularNumber/CircularNumber";
import {TypeActionModal} from "../../../domain/models/TypeActionsModal";

interface INewBallotsProps {
    handleOpenModal: (type: TypeActionModal) => void;
}

export default function NewBallots({
                                       handleOpenModal,
                                   }: INewBallotsProps): JSX.Element {

    const dispatch = useAppDispatch();
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const launchingBallot = useAppSelector((state) => state.bingoState.launchingBallot);
    const startingGame = useAppSelector((state) => state.bingoState.startingGame);
    const launch_mode = useAppSelector((state) => state.selectedOptionState.selectedOption);
    const infoConsoleRef = useRef<HTMLDivElement | null>(null);
    const {register, watch, handleSubmit, reset, formState: {errors}} = useForm();
    const new_ballot = watch('new_ballot', '');
    const new_ballot_confirm = watch('new_ballot_confirm', '');
    const lastBallot = useAppSelector((state) => state.bingoState.lastBallot);

    function handledStartGameClick(game_id: number, session_id: number) {
        dispatch(startGame(game_id.toString())).then((res) => {
            dispatch(getSingleBingo(game_id.toString())).then(r => {
                dispatch(getAllBingos(session_id.toString()));
            })
        });
    }
    function handleThrowBallot(game_id: number, session_id: number, launch_mode: string, new_ballot: string, new_ballot_confirm: string) {
        if (launch_mode === 'semi') {
            let dataLaunch: LaunchBallotModel = {
                'game_id': game_id.toString(),
                'launch_mode': launch_mode,
                'new_ballot': ''
            }
            console.log(dataLaunch);
            dispatch(launchBallot(dataLaunch)).then((res) => {
                dispatch(getSingleBingo(dataLaunch.game_id)).then(r => {
                    dispatch(getAllBingos(session_id.toString()));
                    dispatch(updateSelectedOptions());

                    reset();
                })
            });
        }
        if (launch_mode === 'manual') {
            console.log(new_ballot.length);
            if (new_ballot !== new_ballot_confirm) {
                alertError('Balotas no coinciden');
                return;
            }
            if (new_ballot === new_ballot_confirm) {
                let dataLaunch: LaunchBallotModel = {
                    'game_id': game_id.toString(),
                    'launch_mode': launch_mode,
                    'new_ballot': new_ballot
                }
                console.log(dataLaunch);
                dispatch(launchBallot(dataLaunch)).then((res) => {
                    dispatch(getSingleBingo(dataLaunch.game_id)).then(r => {
                        dispatch(getAllBingos(session_id.toString()));
                        dispatch(updateSelectedOptions());
                        reset();
                    });
                });
            }
        }
        if (launch_mode === 'auto') {
            let dataLaunch: LaunchBallotModel = {
                'game_id': game_id.toString(),
                'launch_mode': launch_mode,
                'new_ballot': ''
            }
            console.log(dataLaunch);
            dispatch(launchBallot(dataLaunch)).then((res) => {
                dispatch(getSingleBingo(dataLaunch.game_id)).then(r => {
                    dispatch(getAllBingos(session_id.toString()));
                    dispatch(updateSelectedOptions());
                    reset();
                });
            });
        }
    }

    useEffect(() => {
        function scrollToBottom() {
            if (infoConsoleRef.current) {
                const scrollHeight = infoConsoleRef.current.scrollHeight;
                const clientHeight = infoConsoleRef.current.clientHeight;
                const maxScrollTop = scrollHeight - clientHeight;
                infoConsoleRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            }
        }

        scrollToBottom();
    }, []);

    function dataWinners() {
        if (singleBingo?.winners !== undefined) {
            const winnerArray = Object.values(singleBingo?.winners);
            console.log(winnerArray);
        }
    }

    useEffect(() => {
        console.log(lastBallot);
        dataWinners();
    }, []);

    return (
        <NewBallotsStyles>
            <div className="container-new-ballot">
                <h2>Nueva balota: </h2>
                <div className="random-toss">
                    <input
                        type="radio"
                        id="random-toss"
                        value="semi"
                        name="launchType"
                        checked={launch_mode === 'semi'}
                        onChange={(e) => dispatch(changeSelectedOptions(e.target.value))}
                    />
                    <label
                        htmlFor="random-toss"
                        className={launch_mode !== 'semi' ? 'selected-label' : ''}
                    >
                        Lanzamiento Aleatorio
                    </label>
                </div>
                <div className="hand-launch">
                    <input
                        type="radio"
                        id="hand-launch"
                        value="manual"
                        name="launchType"
                        checked={launch_mode === 'manual'}
                        onChange={(e) => dispatch(changeSelectedOptions(e.target.value))}
                    />
                    <label
                        className={launch_mode !== 'manual' ? 'selected-label' : ''}
                        htmlFor="hand-launch"
                    >Lanzamiento Manual</label>
                    <div className="container-inputs">
                        <form>
                            <input
                                {...register('new_ballot')}
                                className={launch_mode !== 'manual' ? 'input' : ''}
                                type="text"
                                placeholder="Ingrese balota"
                                disabled={launch_mode !== 'manual'}
                                maxLength={2}
                            />
                            <input
                                {...register('new_ballot_confirm')}
                                className={launch_mode !== 'manual' ? 'input' : ''}
                                type="text"
                                placeholder="Confirme balota"
                                disabled={launch_mode !== 'manual'}
                                maxLength={2}
                            />
                        </form>
                    </div>
                </div>
                {/*<div className="automatic-release">*/}
                {/*    <input*/}
                {/*        type="radio"*/}
                {/*        id="automatic-release"*/}
                {/*        value="auto"*/}
                {/*        name="launchType"*/}
                {/*        checked={launch_mode === 'auto'}*/}
                {/*        onChange={(e) => dispatch(changeSelectedOptions(e.target.value))}*/}
                {/*    />*/}
                {/*    <label*/}
                {/*        htmlFor="automatic-release"*/}
                {/*        className={launch_mode !== 'auto' ? 'selected-label' : ''}*/}
                {/*    >*/}
                {/*        Lanzamiento Automatico*/}
                {/*    </label>*/}
                {/*</div>*/}
                {/*<div className={launch_mode === 'auto' ? "container-checkbox" : "container-checkbox selected-label"}>*/}
                {/*    <input disabled={launch_mode !== 'auto'} type="checkbox" id="checkbox-1"/>*/}
                {/*    <label htmlFor="checkbox-1"><i className="fas fa-check"></i>15s</label>*/}

                {/*    <input disabled={launch_mode !== 'auto'} type="checkbox" id="checkbox-2"/>*/}
                {/*    <label htmlFor="checkbox-2"><i className="fas fa-check"></i>30s</label>*/}

                {/*    <input disabled={launch_mode !== 'auto'} type="checkbox" id="checkbox-3"/>*/}
                {/*    <label htmlFor="checkbox-3"><i className="fas fa-check"></i>45s</label>*/}
                {/*</div>*/}
            </div>

            {singleBingo && singleBingo.start_time !== '' && singleBingo.end_time === '' && !startingGame &&
                <div className="container-throw-ballot">

                    {/*game initialized, throw ballot: */}
                    {!launchingBallot && <>
                        <div className="logo-launch"
                             onClick={() => handleThrowBallot(singleBingo.game_id, singleBingo?.session_id, launch_mode, new_ballot, new_ballot_confirm)}>
                            <img alt="logo-launch" src="/img/imgAdmin/logo-launch.svg"/>
                            <CircularNumber
                                position="unset"
                                width={6}
                                height={6}
                                background='transparent'
                                border={"0.4817756414413452px solid #88A90F"}
                                color='#878787'
                                font_size={1.3}
                                number_selected={lastBallot !== '' ? lastBallot : '?'}
                            />
                        </div>
                        <div className="text-launch">
                            <h2>Lanzar Balota</h2>
                        </div>
                        <span onClick={() => handleOpenModal('winners')} className="show-winners">Ver ganadores</span>
                    </>}

                    {/*waiting for response when casting the ballot: */}
                    {launchingBallot && <>
                        <div className="logo-launch">
                            <img src="/img/imgAdmin/loading-info.svg"/>
                        </div>
                        <div className="text-launch">
                            <h2>Lanzando Balota</h2>
                        </div>
                    </>}
                    {/*{singleBingo.ballots.length > 0 && <div className="undo-ballot">*/}
                    {/*    <img src="/img/imgAdmin/left-arrow.svg"/>*/}
                    {/*    <span>Deshacer ultima balota</span>*/}
                    {/*</div>}*/}
                </div>
            }
            {singleBingo && singleBingo.start_time === '' && !startingGame &&
                <div onClick={() => handledStartGameClick(singleBingo?.game_id, singleBingo?.session_id)}
                     className="container-throw-ballot">
                    <div className="logo-launch">
                        <div className="img-border">
                            <img alt="logo-launch" src="/img/imgAdmin/logo-launch.svg"/>
                        </div>
                    </div>
                    <div className="text-launch">
                        <h2>Iniciar juego</h2>
                    </div>
                </div>
            }
            {singleBingo && singleBingo.start_time === '' && startingGame &&
                <div onClick={() => handledStartGameClick(singleBingo?.game_id, singleBingo?.session_id)}
                     className="container-throw-ballot">
                    <div className="logo-launch">
                        <img alt="logo-launch" src="/img/imgAdmin/loading-info.svg"/>
                    </div>
                    <div className="text-launch">
                        <h2>Iniciando juego</h2>
                    </div>
                </div>
            }
            {singleBingo && singleBingo.end_time !== '' && !startingGame &&
                <div className="container-throw-ballot">
                    <div className="logo-launch">
                        <div className="img-border">
                            <img alt="logo-launch" src="/icon/iconAdmin/check-green.svg"/>
                        </div>
                    </div>
                    <div className="text-launch">
                        <h2>Juego Finalizado</h2>
                    </div>
                    <span onClick={() => handleOpenModal('winners')} className="show-winners">Ver ganadores</span>
                </div>
            }
            <div className="session-console">
                <div className="info-console" ref={infoConsoleRef}>
                    <div className="scrollable-content">
                        {singleBingo && singleBingo.ballots.map((info, index) => (
                            <span key={index}>
                                [{info[0]}] :{info[2]}
                            </span>
                        ))}
                        {singleBingo && singleBingo.start_time !== '' && (
                            <span>[{singleBingo.start_time}]: Inicio del juego</span>
                        )}
                    </div>
                </div>
            </div>

        </NewBallotsStyles>
    );
}