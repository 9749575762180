import styled from "styled-components";

export const ShowWinnersStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5vw;
  width: 40%;
  padding: 2vw;
  border-radius: 10px;
  background: whitesmoke;

  .button-close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
    font-weight: bold;
    width: 1vw;
    height: 1vw;
    border: 2px solid #E4E4E4;
    border-radius: 100%;
    position: absolute;
    top: 1vw;
    right: 1vw;
    cursor: pointer;
  }
  
  
  
  .container-winner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border: 1px solid rgba(168,174,174,0.73);
    padding: .5vw;
    border-radius: 5px;
  }
`
