import styled from "styled-components";

export const ComponentGamersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: .7vw;
  background: #FFFFFF73 0% 0% no-repeat padding-box;
  border: 4px solid #FFFFFF;
  border-radius: 28px;
  opacity: 1;
  height: 75%;
  padding: 1vw .5vw;
`