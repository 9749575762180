import {AccumulatedStyles} from "./AccumulatedStyles";

export default function Accumulated():JSX.Element {

    return (
        <AccumulatedStyles>
            <span className="p-accumulated"> Acumulado para premio extra:</span>
            <div className="rectangle"> 
                <div className="rectangle2"><span className="rectangle-text">3032/4000</span>
                </div>
            </div>
            <span className="p2-accumulated"> Toyota Corolla SEG 2023</span>
        </AccumulatedStyles>
    );
}