import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    InputBase,
    Paper,
    Typography,
} from "@mui/material";
import {colors} from "../../Theme/theme";
import {useState} from "react";


function VisibilityOn() {
    return null;
}

const CustomInput: React.FC<{
    isIconActive: boolean;
    label: string;
    placeholder: string;
    onChange: (value: any) => void;
}> = ({ isIconActive, label, placeholder, onChange }) => {
    const [showText, setShowText]= useState(false);

    function toggleShowText() {
        setShowText(!showText);
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            justifyContent="flex-start"
            mb={2}
        >
            <Box display="flex" flexDirection="column" justifyContent="flex-start">
                <Typography color="white" pb={1}>
                    {label}
                </Typography>
                <Paper
                    sx={{
                        background: colors.input[100],
                        width: "100%"
                    }}
                >
                    <InputBase
                        placeholder={placeholder}
                        fullWidth
                        sx={{
                            bgcolor: colors.input[100],
                            p: 1,
                            borderRadius: "5px",
                        }}
                        onChange={onChange}
                        type={!showText && isIconActive ? "password" : "text"}
                        endAdornment={
                            isIconActive && (
                                <InputAdornment position="end" sx={{ pr: 1 }}>
                                    {showText && <IconButton onClick={toggleShowText} edge="end">
                                        <VisibilityOff />
                                    </IconButton>}
                                    {!showText && <IconButton onClick={toggleShowText} edge="end">
                                        <Visibility />
                                    </IconButton>}
                                </InputAdornment>
                            )
                        }
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default CustomInput;