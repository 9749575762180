import {DataGamersStyled} from "./DataGamersStyled";

export interface IDataGamers {
    imgAvatar: string;
    text: string;
    name: string;
}

export default function DataGamers({
                                       imgAvatar,
                                       text,
                                       name,
                                   }: IDataGamers): JSX.Element {

    return (
        <DataGamersStyled>
            <div className="container-avatar-gamer">
                <img alt="avatar-gamer" className="avatar-gamer" src={imgAvatar}/>
                <img alt="soccer-ball-avatar" className="soccer-ball-avatar" src="/img/soccer_ball_small.png"/>
            </div>
            <div className="container-text-info">
                <div className="text">{text}</div>
                <div className="name">{name}</div>
            </div>
        </DataGamersStyled>
    );
}