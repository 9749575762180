export function cardLimit(value:string) {
    if (!value) {
        return "El límite de tarjetones es requerido";
    }
    const card_limit:number = parseInt(value, 10);
    if (isNaN(card_limit)) {
        return "Ingresa un número válido";
    }
    if (card_limit > 21) {
        return "El límite de tarjetones debe ser menor o igual 21";
    }
    if (card_limit <= 0) {
        return "El límite de tarjetones debe ser mayor a 0";
    }
    return true;
}
export function cardPrice(value:string) {
    if (!value) {
        return "El límite de tarjetones es requerido";
    }
    const card_price:number = parseInt(value, 10);
    if (isNaN(card_price)) {
        return "Ingresa un número válido";
    }
    if (card_price < 0) {
        return "El precio del bingo debe ser mayor o igual a 0";
    }
    return true;
}
export function getTodayDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}
