export const GET_ALL_SESSIONS = 'GET_ALL_SESSIONS';
export const GET_ALL_SESSIONS_SUCCESS = 'GET_ALL_SESSIONS_SUCCESS';
export const GET_ALL_SESSIONS_FAILED = 'GET_ALL_SESSIONS_FAILED';
export const GET_SINGLE_SESSION = 'GET_SINGLE_SESSION';
export const GET_SINGLE_SESSION_SUCCESS = 'GET_SINGLE_SESSION_SUCCESS';
export const GET_SINGLE_SESSION_FAILED = 'GET_SINGLE_SESSION_FAILED';
export const EDIT_SESSION = 'EDIT_SESSION';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const EDIT_SESSION_FAILED = 'EDIT_SESSION_FAILED';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILED = 'DELETE_SESSION_FAILED';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';
export const CLEAN_SINGLE_SESSION = 'CLEAN_SINGLE_SESSION';
export const CLEAN_ALL_SESSIONS = 'CLEAN_ALL_SESSIONS';


