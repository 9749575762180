export function winnerNumber(value:string) {
    console.log(value);
    if (!value) {
        return "el limite de ganadores es requerido";
    }
    let winner_number:number = parseInt(value, 10);
    if (isNaN(winner_number)) {
        return 'Ingresa un número válido';
    }
    if (winner_number < 1) {
        return 'El número debe ser igual o mayor que 1';
    }
    return true;
}