import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./ReducerAdmin/AdminReducer";
import AdminReducer from "./ReducerAdmin/AdminReducer";

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }),
    reducer: AdminReducer
});

export type AdminState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;