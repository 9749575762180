import axios from "axios";
import {
    CLEAN_ALL_BINGOS, CLEAN_LAST_BALLOT,
    CLEAN_SINGLE_BINGO,
    CREATE_BINGO,
    CREATE_BINGO_FAILED,
    CREATE_BINGO_SUCCESS, EDIT_BINGO, EDIT_BINGO_FAILED, EDIT_BINGO_SUCCESS,
    GET_ALL_BINGOS,
    GET_ALL_BINGOS_FAILED,
    GET_ALL_BINGOS_SUCCESS, GET_SINGLE_BINGO, GET_SINGLE_BINGO_FAILED, GET_SINGLE_BINGO_SUCCESS,
    LAUNCH_BALLOT,
    LAUNCH_BALLOT_FAILED,
    LAUNCH_BALLOT_SUCCESS,
    START_GAME,
    START_GAME_FAILED,
    START_GAME_SUCCESS, UPDATE_LAST_BALLOT
} from "../TypesAdmin/BingosType";
import {CreateBingoModel} from "../../domain/models/CreateBingoModel";
import {LaunchBallotModel} from "../../domain/models/LaunchBallotModel";
import {ListAllBingosModel} from "../../domain/models/ListAllBingosModel";
import {EditBingoModel} from "../../domain/models/EditBingoModel";
import {showLastBallot} from "../../shared/Utils/ShowLastBallot";
import {
    createBingoTesting, editBingoTesting,
    getAllBingosTesting,
    getSingleBingosTesting, launchBallotTesting, startGameTesting
} from "../../shared/Utils/TestingApi/TestingApi";

export const getAllBingos = (session_id:string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    // console.log("excecuting get all bingos");
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: getAllBingosTesting,
        // url: getAllBingosProduction,
        headers: {
            // 'x-api-key': 'EbwINfmf1Q2ypCqisPdgy26dWMmvDnD36gnWepw2',
            'games_info': 'ok',
            'session_id': session_id,
        }
    };
    dispatch({
        type: GET_ALL_BINGOS,
        payload: 'loanding'
    });
    axios.request(config)
        .then((response) => {
            let resData:ListAllBingosModel[] = response.data as ListAllBingosModel[];
            console.log(resData);
            const sortedList:ListAllBingosModel[] = [...resData].sort((a, b) =>
                a.game_id - b.game_id
            );
            console.log(sortedList);
            dispatch({
                type: GET_ALL_BINGOS_SUCCESS,
                payload: sortedList
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_BINGOS_FAILED,
                payload: error
            });
        });
}
export const getSingleBingo = (game_id:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(game_id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: getSingleBingosTesting,
        // url: getSingleBingosProduction,
        headers: {
            // 'x-api-key': 'EbwINfmf1Q2ypCqisPdgy26dWMmvDnD36gnWepw2',
            'one_game_info': 'ok',
            'game_id': game_id
        }
    };
    dispatch({
        type: GET_SINGLE_BINGO,
        payload: 'loading'
    });
    dispatch({
        type: CLEAN_LAST_BALLOT,
        payload: ''
    });
    console.log("calling single bingo");
    try {
        await axios.request(config)
            .then((response) => {
                let data:ListAllBingosModel = response.data;
                console.log(data);
                if (data.ballots.length > 0) {
                    let lastBallot:string = showLastBallot(data);
                    console.log(lastBallot);
                    dispatch({
                        type: UPDATE_LAST_BALLOT,
                        payload: lastBallot
                    });
                }
                dispatch({
                    type: GET_SINGLE_BINGO_SUCCESS,
                    payload: data
                });
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: GET_SINGLE_BINGO_FAILED,
                    payload: error
                });
            });
    }catch (e) {
        console.log(e);
        dispatch({
            type: GET_SINGLE_BINGO_FAILED,
            payload: e
        });
    }

}
export const createBingo = (data:CreateBingoModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: createBingoTesting,
        // url: createBingoProduction,
        headers: {
            // 'x-api-key': 'RsvupNHnLG1DpC06zzRkd3ghBLdGqAZD1gbVuvi8',
            'create_game': data.create_game,
            'game_mode': data.game_mode,
            'prize': data.prize,
            'bingo_header': 'bingo',
            // 'name_table_users': data.name_table_users,
            // 'bingo_limit': data.bingo_limit,
            'session_id': data.session_id,
            'buy_status': data.buy_status,
            'bingo_name': data.bingo_name,
            'winners_number': data.winners_number
        }
    };
    dispatch({
        type: CREATE_BINGO,
        payload: 'loanding'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: CREATE_BINGO_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_BINGO_FAILED,
                payload: error
            });
        });
}
export const launchBallot = (data_launch:LaunchBallotModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: launchBallotTesting,
        // url: launchBallotProduction,
        headers: {
            // 'x-api-key': 'r31IaPamGX8kw9zGon5xpa5wMzZllHSZ76s4b1O6',
            'game_id': data_launch.game_id,
            'new_ballot': data_launch.new_ballot,
            'launch_mode': data_launch.launch_mode
        }
    };
    dispatch({
        type: LAUNCH_BALLOT,
        payload: 'loanding'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: LAUNCH_BALLOT_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: LAUNCH_BALLOT_FAILED,
                payload: error.response.data
            });
        });
}
export const startGame = (start_game_id:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(start_game_id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: startGameTesting,
        // url: startGameProduction,
        headers: {
            // 'x-api-key': 'RsvupNHnLG1DpC06zzRkd3ghBLdGqAZD1gbVuvi8',
            'start_game_id': start_game_id
        }
    };
    dispatch({
        type: START_GAME,
        payload: 'loanding'
    });
   await axios.request(config)
        .then((response) => {
            dispatch({
                type: START_GAME_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: START_GAME_FAILED,
                payload: error
            });
        });
}
export const editBingo = (dateEdit:EditBingoModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(dateEdit);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: editBingoTesting,
        // url: editBingoProduction,
        headers: {
            'edit_game': 'ok',
            'new_bingo_name': dateEdit.new_bingo_name,
            'new_prize': dateEdit.new_prize,
            'new_game_mode': dateEdit.new_game_mode,
            'winners_number': '',
            'game_id': dateEdit.game_id
        }
    };
    dispatch({
        type: EDIT_BINGO,
        payload: 'loanding'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_BINGO_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_BINGO_FAILED,
                payload: error
            });
        });
}
export const cleanSingleBingo = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SINGLE_BINGO,
        payload: null
    });
}
export const cleanAllBingos = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_BINGOS,
        payload: null
    });
}
export const cleanLastBallot = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_BINGOS,
        payload: ''
    });
}