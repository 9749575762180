import {CardBingoModel} from "../../domain/models/CardBingoModel";

export const dataCardBingo:CardBingoModel[] = [
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
    {
        'serialId': 'SERIAL: A672C',
        'dataNumber': {
            'B1': '15',
            'I1': '20',
            'N1': '36',
            'G1': '50',
            'O1': '63',
            'B2': '12',
            'I2': '29',
            'N2': '41',
            'G2': '59',
            'O2': '71',
            'B3': '4',
            'I3': '30',
            'N3': '',
            'G3': '47',
            'O3': '67',
            'B4': '9',
            'I4': '26',
            'N4': '39',
            'G4': '49',
            'O4': '74',
            'B5': '3',
            'I5': '21',
            'N5': '42',
            'G5': '51',
            'O5': '70',
        }
    },
];