import {NumberStyles} from "./NumberStyles";
import CircularNumber from "../CircularNumber/CircularNumber";
import {useEffect, useState} from "react";
import {dataNumber, dataNumber25, dataNumber50, dataNumber75} from "../../../shared/Utils/DataNumber";
import {useAppSelector} from "../../../shared/Utils/Hooks";

interface IShowNumberInfo {
    show: boolean,
    typeRef: string
}

export default function Numbers(): JSX.Element {

    const [selectedNumber, setSelectedNumber] = useState<string>("");
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);

    function handleShowNumberInfo(number: string) {
        console.log(number);
        setSelectedNumber(number);
    }

    function closeSelectedNumber(dataClose:string) {
        setSelectedNumber(dataClose);
        console.log(dataClose);
    }

    useEffect(() => {
        console.log(selectedNumber);
    }, []);

    return (
        <NumberStyles>
            {singleSession && singleSession.bingo_limit === 25 && dataNumber25.map((data) => (
                <div className="container-numbers" key={data.number}>
                    <CircularNumber
                        onClick={()=>handleShowNumberInfo(data.number)}
                        position="unset"
                        width={2}
                        height={1.7}
                        background={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#FD5000" : "transparent" }
                        border={"0.4817756414413452px solid #878787"}
                        color={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#ffffff" : "#878787" }
                        font_size={1.3}
                        number_selected={data.number}
                    />
                    { selectedNumber !== "" && selectedNumber === data.number && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == selectedNumber) &&
                        <div className="number-info">
                            <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X</div>
                            <div className="info">
                                <span className="text-bold">Tipo de Lanzamiento:</span>
                                <span className="text">{singleBingo?.game_mode}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Hora de Lanzamiento:</span>
                                <span className="text">{data.dataInfo.hour}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Usuario:</span>
                                <span className="text">{singleSession?.session_name}</span>
                            </div>
                        </div>
                    }
                </div>
            ))}

            {singleSession && singleSession.bingo_limit === 50 && dataNumber50.map((data) => (
                <div className="container-numbers" key={data.number}>
                    <CircularNumber
                        onClick={()=>handleShowNumberInfo(data.number)}
                        position="unset"
                        width={2}
                        height={1.7}
                        background={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#FD5000" : "transparent" }
                        border={"0.4817756414413452px solid #878787"}
                        color={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#ffffff" : "#878787" }
                        font_size={1.3}
                        number_selected={data.number}
                    />
                    { selectedNumber !== "" && selectedNumber === data.number && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == selectedNumber) &&
                        <div className="number-info">
                            <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X</div>
                            <div className="info">
                                <span className="text-bold">Tipo de Lanzamiento:</span>
                                <span className="text">{singleBingo?.game_mode}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Hora de Lanzamiento:</span>
                                <span className="text">{data.dataInfo.hour}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Usuario:</span>
                                <span className="text">{singleSession?.session_name}</span>
                            </div>
                        </div>
                    }
                </div>
            ))}

            {singleSession && singleSession.bingo_limit === 75 && dataNumber75.map((data) => (
                <div className="container-numbers" key={data.number}>
                    <CircularNumber
                        onClick={()=>handleShowNumberInfo(data.number)}
                        position="unset"
                        width={2}
                        height={1.7}
                        background={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#FD5000" : "transparent" }
                        border={"0.4817756414413452px solid #878787"}
                        color={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#ffffff" : "#878787" }
                        font_size={1.3}
                        number_selected={data.number}
                    />
                    { selectedNumber !== "" && selectedNumber === data.number && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == selectedNumber) &&
                        <div className="number-info">
                            <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X</div>
                            <div className="info">
                                <span className="text-bold">Tipo de Lanzamiento:</span>
                                <span className="text">{singleBingo?.game_mode}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Hora de Lanzamiento:</span>
                                <span className="text">{data.dataInfo.hour}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Usuario:</span>
                                <span className="text">{singleSession?.session_name}</span>
                            </div>
                        </div>
                    }
                </div>
            ))}

            {singleSession && singleSession.bingo_limit === 99 && dataNumber.map((data) => (
                <div className="container-numbers" key={data.number}>
                    <CircularNumber
                        onClick={()=>handleShowNumberInfo(data.number)}
                        position="unset"
                        width={2}
                        height={1.7}
                        background={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#FD5000" : "transparent" }
                        border={"0.4817756414413452px solid #878787"}
                        color={singleBingo && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == data.number) ?  "#ffffff" : "#878787" }
                        font_size={1.3}
                        number_selected={data.number}
                    />
                    { selectedNumber !== "" && selectedNumber === data.number && singleBingo?.ballots.map((ballotInfo) => ballotInfo[2]).find(element => element == selectedNumber) &&
                        <div className="number-info">
                            <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X</div>
                            <div className="info">
                                <span className="text-bold">Tipo de Lanzamiento:</span>
                                <span className="text">{singleBingo?.game_mode}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Hora de Lanzamiento:</span>
                                <span className="text">{data.dataInfo.hour}</span>
                            </div>
                            <div className="info">
                                <span className="text-bold">Usuario:</span>
                                <span className="text">{singleSession?.session_name}</span>
                            </div>
                        </div>
                    }
                </div>
            ))}
        </NumberStyles>
    );
}