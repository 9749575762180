import styled from "styled-components";

export const LastBallotsStyles = styled.div`
  width: 96%;
  margin: 0 1.9vw .5vw;
  padding: 0 .5vw .6vw;
  

  h2 {
    font-size: 1.25em;
    text-align: left;
    letter-spacing: 0px;
    color: #686868;
    opacity: 1;
    margin: 0;
  }

  .container-bar {
    width: 98%;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    

    .triangle-box {
      width: 2vw;
      height: 1.5vw;
      position: relative;
      background: #848484 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
      cursor: pointer;

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 6px 11px;
        border-color: transparent transparent transparent #CBCBCB;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-70%, -50%) rotate(180deg);
        background: #CBCBCB 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      .triangle-right {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 6px 11px;
        border-color: transparent transparent transparent #CBCBCB;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #CBCBCB 0% 0% no-repeat padding-box;
        opacity: 1;
      }
    }

    .triangle-box-right{
      flex-grow: 1;
      overflow-x: hidden;
      
      .numbers-container {
        display: flex;
        align-items: center;
        justify-content: end;

        .numbers{
          flex-shrink: 0;
          white-space: nowrap;
          color: #878787;
          text-align: right;
          letter-spacing: 0;
          opacity: 1;
          font-size: .9em;
          font-weight: bold;
        } 
      }
    }
  }


`