import {InfoPlayStyled} from "./InfoPlayStyled";

export default function InfoPlay():JSX.Element {

    return (
        <InfoPlayStyled>
            <span className="text-1">Estamos Jugando:</span>
            <span className="text-2">TABLERO EN L</span>
            <div className="text-3">Toda la Columa B Toda la fila inferior</div>
            <img alt="bingoL" className="bingoL" src="/img/img_bingoL.svg"/>
        </InfoPlayStyled>
    );
}