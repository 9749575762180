import {ProgressBarStyled} from "./ProgressBarStyled";
import LinearProgress from '@mui/material/LinearProgress';
import React from "react";


export default function ProgressBar():JSX.Element {

    const progress:number = 60;
    const imageRef = React.useRef<HTMLImageElement>(null);


    React.useEffect(() => {
        if (imageRef.current) {
            const containerWidth = imageRef.current.parentElement?.offsetWidth || 0;
            const newPosition = (containerWidth * progress) / 100;
            imageRef.current.style.transform = `translateX(${newPosition}px)`;
        }
    }, []);

    return (
        <ProgressBarStyled>
            <LinearProgress
                className="linearProgress"
                variant="determinate"
                value={progress}
            />
            <img ref={imageRef} alt="soccer-ball" className="soccer-ball" src="/img/soccer_ball_big.png"/>
            <div className="container-soccer-goal">
                <div className="container-soccer-goal-text">
                    <img alt="soccer-goal" className="soccer-goal" src="/img/soccer_goal_big.png"/>
                    <p className="soccer-goal-text">5 premios EXTRA</p>
                </div>
            </div>
        </ProgressBarStyled>
    );
}