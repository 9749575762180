import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderDivider,
    CHeaderNav,
    CHeaderToggler,
    CNavLink,
    CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {AppHeaderStyled} from "./AppHeaderStyled";
import {Logout} from "@mui/icons-material";
import {history} from "../../../../../App";
// import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'


export function AppHeader():JSX.Element  {


    function logout() {
        sessionStorage.removeItem("user_secret");
        history.push("/login")
    }

    return (
        <AppHeaderStyled>
            <div className="container-user">
                <span className="name-user">Hola, Daniel</span>
                <img src="/img/imgAdmin/img-user-small.png"/>
                <a onClick={logout}>
                    <Logout/>
                </a>
            </div>
        </AppHeaderStyled>
    )
}

