import styled from "styled-components";

export const AppHeaderStyled = styled.div `
  width: 100%;
  height: 4%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  
  .container-user{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-right: 1rem;
    
    .name-user{
      text-align: left;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
      font-family: Poppins-Bold,serif;
      font-size: 12.84px;
      font-weight: bold;
    }
  }
  
  
  
  
`