import styled from "styled-components";

export const LoginStyles = styled.div`
  background-image: url("https://m.media-amazon.com/images/I/B1MIfmvGDOS.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  
  .error_msg{
    color: red;
  }
  .logo_wplay{
    
    width: 250px;
  }
;
`