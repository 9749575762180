import {ListAllBingosModel} from "../../domain/models/ListAllBingosModel";
import {
    CLEAN_ALL_BINGOS, CLEAN_LAST_BALLOT,
    CLEAN_SINGLE_BINGO,
    CREATE_BINGO,
    CREATE_BINGO_FAILED,
    CREATE_BINGO_SUCCESS, EDIT_BINGO, EDIT_BINGO_FAILED, EDIT_BINGO_SUCCESS,
    GET_ALL_BINGOS,
    GET_ALL_BINGOS_FAILED,
    GET_ALL_BINGOS_SUCCESS,
    GET_SINGLE_BINGO,
    GET_SINGLE_BINGO_FAILED,
    GET_SINGLE_BINGO_SUCCESS,
    LAUNCH_BALLOT, LAUNCH_BALLOT_FAILED,
    LAUNCH_BALLOT_SUCCESS,
    START_GAME,
    START_GAME_SUCCESS, UPDATE_LAST_BALLOT
} from "../TypesAdmin/BingosType";

interface IStateBingo {
    allBingos:ListAllBingosModel[]  | null;
    loadingAllBingos:boolean;
    startingGame:boolean;
    singleBingo:ListAllBingosModel | null;
    loadingSingleBingo:boolean;
    createBingo:string;
    loadingCreateBingo:boolean;
    launchingBallot:boolean;
    startedGame:boolean;
    message:string
    blockedLaunch:boolean;
    loadingEditBingo:boolean
    lastBallot:string;
}
const initialStateBingo: IStateBingo = {
    allBingos: [],
    loadingAllBingos:false,
    singleBingo: null,
    loadingSingleBingo:false,
    createBingo:'',
    loadingCreateBingo:false,
    launchingBallot:false,
    startedGame:false,
    message:'',
    blockedLaunch:false,
    startingGame:false,
    loadingEditBingo:false,
    lastBallot: '',
}

const BingoReducer = function (state = initialStateBingo, action: {type:any; payload:any}):IStateBingo {
    switch (action.type) {
        case GET_ALL_BINGOS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                launchingBallot: state.launchingBallot,
                allBingos: state.allBingos,
                loadingAllBingos:true,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case GET_ALL_BINGOS_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: action.payload,
                loadingAllBingos:false,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case GET_ALL_BINGOS_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: null,
                loadingAllBingos:false,
                singleBingo:null,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:action.payload
            };
        case GET_SINGLE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:true,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case GET_SINGLE_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:action.payload,
                loadingSingleBingo:false,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case GET_SINGLE_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:false,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:"Error al cargar el bingo"
            };
        case CREATE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:true,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case CREATE_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:action.payload,
                loadingCreateBingo:false,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case CREATE_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:false,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:action.payload
            };
        case LAUNCH_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: true,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case LAUNCH_BALLOT_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: false,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case LAUNCH_BALLOT_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: true,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: false,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:action.payload
            };
        case START_GAME:
            return {
                startingGame: true,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:false,
                startedGame:true,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case START_GAME_SUCCESS:
            return {
                startingGame: false,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:false,
                startedGame:true,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case EDIT_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:true,
                lastBallot:state.lastBallot,
                message:''
            };
        case EDIT_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:false,
                lastBallot:state.lastBallot,
                message:''
            };
        case EDIT_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:false,
                lastBallot:state.lastBallot,
                message:action.payload
            };
        case CLEAN_SINGLE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:action.payload,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingSingleBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case CLEAN_ALL_BINGOS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: action.payload,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingSingleBingo,
                lastBallot:state.lastBallot,
                message:''
            };
        case UPDATE_LAST_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingSingleBingo,
                lastBallot:action.payload,
                message:''
            };
        case CLEAN_LAST_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingSingleBingo,
                lastBallot:action.payload,
                message:''
            };
        default:
            return state;
    }
}
export default BingoReducer;