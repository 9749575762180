import {SectionMainHeaderStyled} from "./SectionMainHeaderStyled";
import CircularNumber from "../CircularNumber/CircularNumber";
import SwitchButton from "../SwitchButton/SwitchButton";

interface numberBar {
    number:string;
}
export default function SectionMainHeader():JSX.Element {

    const number_bar:numberBar[] = [
        {'number':'13'},
        {'number':'76'},
        {'number':'46'},
        {'number':'34'},
        {'number':'23'},
        {'number':'59'},
        {'number':'14'},
        {'number':'03'},
        {'number':'03'},
        {'number':'03'},
        {'number':'03'},
    ];


    return (
        <SectionMainHeaderStyled>
            <div className="my-cards">
                <div className="title-card">Mis tarjetones</div>
                <div className="switch-1">
                    <span className="text">Vista Avanzada</span>
                    <SwitchButton switchProp={false} refButton="button1"/>
                </div>
                <div className="switch-1">
                    <span className="text">Vista Avanzada</span>
                    <SwitchButton switchProp={true} refButton="button2"/>
                </div>
            </div>
            <div className="container-number-ball">
                <div className="number-bar">
                    <CircularNumber
                        position="absolute"
                        width={5}
                        height={5}
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="5px solid #707070"
                        color="#E09500"
                        font_size={3.5}
                        number_selected='25'
                    />
                    <div className="number-bar-grid">
                        {number_bar && number_bar.map((number) => (
                            <CircularNumber
                                position="unset"
                                width={2.6}
                                height={2.6}
                                background="#FFFFFF5E 0% 0% no-repeat padding-box"
                                border="none"
                                color="#FFFFFF"
                                font_size={1.8}
                                number_selected={number.number}
                            />
                        ))}
                        <span>V</span>
                    </div>
                </div>
            </div>
        </SectionMainHeaderStyled>
    );
}