import styled from "styled-components";

export const AdminHomeStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .selectSession {
    width: 16%;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    background: #F1F1F150;

    //.button-close-modal {
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  font-size: 1.2vw;
    //  font-weight: bold;
    //  width: 1vw;
    //  height: 1vw;
    //  border: 2px solid #E4E4E4;
    //  border-radius: 100%;
    //  position: absolute;
    //  top: 0;
    //  right: 0;
    //  cursor: pointer;
    //}
  }

`