import {CircularNumberStyled} from "./CircularNumberStyled";

interface ICircularNumber {
    position: string;
    width: number;
    height: number;
    background: string;
    border: string;
    color: string;
    font_size: number;
    number_selected: any;
}

export default function CircularNumber({
                                           width,
                                           height,
                                           background,
                                           border,
                                           color,
                                           font_size,
                                           number_selected,
                                           position,
                                       }: ICircularNumber): JSX.Element {

    return (
        <CircularNumberStyled
            width={width}
            height={height}
            background={background}
            border={border}
            color={color}
            font_size={font_size}
            position={position}
        >
            {number_selected}
        </CircularNumberStyled>
    );
}