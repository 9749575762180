import React, {useState} from 'react'

import {FooterStyled} from "./FooterStyled";



export function Footer():JSX.Element  {


    return (
        <FooterStyled>
           <span>30 de Julio de 2023 [16:20]</span>
        </FooterStyled>
    )
}

