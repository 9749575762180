import {HeaderStyled} from "./HeaderStyled";
import ProgressBar from "../ProgressBar/ProgressBar";
import CircularAvatar from "../CircularAvatar/CircularAvatar";

export default function Header():JSX.Element {

    return (
        <HeaderStyled>
            <img className="img-logo" alt="logo" src="/img/logo_small.png"/>
            <span className="text-progress-bar">Total de donaciones</span>
            <ProgressBar/>
            <div className="log-info">
                <div className="log-info-text">
                    <span className="text-info">Daniel robayo</span>
                    <span className="text-info-nuber">3001231234</span>
                </div>
                <CircularAvatar/>
            </div>
        </HeaderStyled>
    );
}