import styled from "styled-components";

export const CreateBingoStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 2vw;
  border-radius: 10px;
  background: whitesmoke;

  .button-close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
    font-weight: bold;
    width: 1vw;
    height: 1vw;
    border: 2px solid #E4E4E4;
    border-radius: 100%;
    position: absolute;
    top: 1vw;
    right: 1vw;
    cursor: pointer;
  }
  .title-bingo {
    font-size: 2vw;
    color: #4C4C4C;
  }
  .session-name {
    font-size: 1vw;
    color: #383838;
  }
  .form-bingo {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    
    .container-input {
      display: flex;
      flex-direction: column;
      
      label {
        font-weight: 600;
        font-size: .9vw;
        color: #A4A4A4;
        margin-bottom: .5vw;
      }
      select {
        border: none;
        outline: none;
        background: transparent;
        border-bottom: 1px solid #88A90F;
        color: #A4A4A4;
        font-size: .8vw;
        
        option {
          font-size: .9vw;
          color: #404041;
        }
      }
      input {
        border: none;
        outline: none;
        background: transparent;
        border-bottom: 1px solid #88A90F;
        color: #404041;
        font-size: .9vw;
      }
      input::placeholder {
        color: #A4A4A4;
        font-size: .8vw;
      }
      .error {
        color: #FF0000;
        font-size: .8vw;
      }
    }
    .button-bingo {
      background: #88A90F;
      color: #FFFFFF;
      border: none;
      outline: none;
      padding: .5vw 0;
      border-radius: 10px;
      margin-top: 3vw;
      cursor: pointer;
    }
  }
`
