import styled from "styled-components";

export const SectionMainHeaderStyled = styled.div`
  height: 25%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 20px;
  
  .my-cards {
    width: 99%;
    height: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    .title-card {
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: left;
      letter-spacing: 0;
      color: #757575;
      opacity: 1;
      font-size: 2.5vw;
      font-family: CoolveticaRg-Regular,serif;
      font-weight: bold;
      padding-left: 1rem;
    }
    .switch-1 {
      display: flex;
      align-items: end;
      justify-content: end;
      
      .text {
        text-align: left;
        letter-spacing: 0;
        color: #1C1C1C;
        opacity: 1;
        font-size: 1.5vw;
        font-family: Poppins-Regular,serif;
      }
    }
  }
  .container-number-ball {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
    
    .number-bar {
      position: relative;
      display: flex;
      width: 95%;
      height: 50%;
      background: #757575;
      border-radius: 30px;
    }
    .number-bar-grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: .7vw;
      align-items: center;
      justify-items: center;
      width: 86%;
      margin-left: 6.5vw;
      
      span {
        color: #FFFFFF;
        font-size: 1.5em;
      }
    }
  }
`