import styled from "styled-components";

export const NumberStyles = styled.div`
  width: 94.5%;
  height: 24%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin: 0 1.5vw .5vw;
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  padding: 0 .5vw .6vw;
  
  .container-numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    position: relative;
    cursor: pointer;
    
    .number-info{
      //border-color: darkgray;
      font-size: 0.5em;
      width: 8rem;
      //height: 8rem;
      //padding: .4rem 0 .3rem 1.5rem;
      position: absolute;
      z-index: 1;
      left: 1.8rem;
      clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #E4E4E4;
      opacity: 1;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      
      .close-selected-number {
        font-size: .8vw;
        color: black;
        text-align: right;
        padding-right: 0.3vw;
        font-weight: bold;
      }
      .info{
        display: flex;
        flex-direction: column;
        padding: 0.1rem 0 .2rem 1.5rem;
      }
      
      .text-bold{
        font-size: 1.3em;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0px;
        color: #4C4C4C;
      }
      
      .text{
        font-size: 1.3em;
        text-align: left;
        letter-spacing: 0px;
        color: #4C4C4C;
      }
    }
  }
`