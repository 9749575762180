import styled from "styled-components";

export const NewBallotsStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: .6fr .4fr 1fr;
  background: #E4E4E4;

  .container-new-ballot {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    margin-left: 0.2vw;
    padding: 0.2rem;

    h2 {
      font-family: Arial;
      font-size: 1.3em;
      text-align: left;
      letter-spacing: 0px;
      color: #686868;
      opacity: 1;
      margin: 0;
      padding-left: 1.2vw;
    }

    .random-toss {
      
      input[type="radio"] {
        -webkit-appearance: none;
       -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 13px;
        height: 13px;
        padding: 1px;
        background-clip: content-box;
        border: 1px solid #333333;
        background-color: white;
        border-radius: 50%;
      }

      input[type="radio"]:checked {
        border-color: #88A90F; /* Estilos para el radio seleccionado */
        background-color: #88A90F;
        /* Otros estilos */
      }
      
      label {
        font-style: italic;
        font-size: 1em;
        text-align: left;
        padding-left: 0.1vw;
        letter-spacing: 0;
        color: #333333;
        opacity: 1;
      }
      .selected-label {
        opacity: 0.24;
      }
    }

    .hand-launch {
      
      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 13px;
        height: 13px;
        padding: 1px;
        background-clip: content-box;
        border: 1px solid #333333;
        background-color: white;
        border-radius: 50%;
      }
      input[type="radio"]:checked {
        border-color: #88A90F; /* Estilos para el radio seleccionado */
        background-color: #88A90F;
      }
      label {
        font-style: italic;
        font-size: 1em;
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
      }
      .selected-label {
        opacity: 0.24;
      }
      .container-inputs {
        margin-top: 1vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 70%;

        form {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          
          input {
            width: 5vw;
            border: none;
            outline: none;
          }
          input::placeholder {
            font-size: 0.7em;
          }
          .input {
            width: 5vw;
            border: none;
            outline: none;
          }
          .input::placeholder {
            opacity: .24;
          }
        }
      }
    }

    .automatic-release {
      
      input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 13px;
        height: 13px;
        padding: 1px;
        background-clip: content-box;
        border: 1px solid #333333;
        background-color: white;
        border-radius: 50%;
      }
      input[type="radio"]:checked {
        border-color: #88A90F; /* Estilos para el radio seleccionado */
        background-color: #88A90F;
      }
      label {
        font-style: italic;
        font-size: 1em;
        text-align: left;
        padding-left: 0.1vw;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
      }
      .selected-label {
        opacity: 0.24;
      }
    }
    .selected-label {
      opacity: 0.24;
    }
    .container-checkbox {
      display: flex;
      gap: 0.9vw;
      padding-left: 1.2vw;
      text-align: left;
      letter-spacing: 0px;
      color: #333333;
      
      input[type="checkbox"]{
        display: none;
      }
      label i {
        display: inline-block;
        width: 0.8vw;
        height: 0.8vw;
        margin-right: 5px;
        background-color: white; /* Cambia el color de fondo aquí */
        border-radius: 2px;
      }
      input[type="checkbox"]:checked + label i{
        background-color: #88A90F;
      }
    }
  }
  
  .container-throw-ballot{
    padding-bottom: 0.5vw;

    .logo-launch{
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 1vw;
      
      .img-border{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: white;
        width: 6vw;
        padding: 0.5rem;
        height: 6vw;
        img{
          width: 100%;
        }
      }
    }
    
    .text-launch{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      h2{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.23em;
        color: #333333;
        margin-top: 1vw;
        width: 60%;
      }
    }
    
    .show-winners {
      display: block;
      width: 100%;
      text-align: center;
      font-size: .9vw;
      color: #88A90F;
      cursor: pointer;
    }
    
    .undo-ballot{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5vw;
      cursor: pointer;
      
      span{
        font-size: .8vw;
        font-weight: bold;
        color: #333333;
      }
    }
  }

  .session-console {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    width: 28vw;
    height: 28vh;
    display: flex;
    flex-direction: column-reverse;
    margin-left: 4.8vw;
    direction: rtl;
    
    .info-console {
      font-family: SourceCodeVariable-Roman, serif;
      font-size: 0.70rem;
      padding-left: 0.1vw;
      text-align: left;
      letter-spacing: 0;
      color: #383838;
      opacity: 1;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column-reverse;
      white-space: nowrap;
      
      .scrollable-content {
        display: flex;
        flex-direction: column;
      }
    }
    .info-console::-webkit-scrollbar {
      width: 5px;
    }
    .info-console::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the scrollbar thumb */
    }
    .info-console::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Color of the scrollbar thumb on hover */
    }
    .info-console::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Color of the scrollbar track */
    }
  }

    

    


  
  




`