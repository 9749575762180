import {ServerStatusStyles} from "./ServerStatusStyles";
import {useAppSelector} from "../../../shared/Utils/Hooks";

export default function ServerStatus():JSX.Element {

    const singleBingo = useAppSelector((state)=>state.bingoState.singleBingo);
    const errMessage = useAppSelector((state)=>state.bingoState.message);

    return (
        <ServerStatusStyles>
            <h2>Server Status: </h2>
                {errMessage != "" ?
                    <div className="status-bar">
                        <div className="container-message">
                            <img src="/img/imgAdmin/red-error.png"/>
                            <span className="massage">{errMessage}</span>
                        </div>
                    </div>: ""}
        </ServerStatusStyles>
    );
}