
import {
    CLEAN_ALL_SESSIONS,
    CLEAN_SINGLE_SESSION,
    CREATE_SESSION,
    CREATE_SESSION_FAILED,
    CREATE_SESSION_SUCCESS,
    DELETE_SESSION, DELETE_SESSION_FAILED,
    DELETE_SESSION_SUCCESS,
    EDIT_SESSION,
    EDIT_SESSION_FAILED,
    EDIT_SESSION_SUCCESS,
    GET_ALL_SESSIONS,
    GET_ALL_SESSIONS_FAILED,
    GET_ALL_SESSIONS_SUCCESS,
    GET_SINGLE_SESSION,
    GET_SINGLE_SESSION_FAILED,
    GET_SINGLE_SESSION_SUCCESS,

} from "../TypesAdmin/SessionTypes";
import {Session, Sessions} from "../../domain/models/CreateSessionModel";

interface IStateSessions {
    allSessions:Sessions;
    loadingAllSessions:boolean;
    singleSession:Session | null;
    loadingSingleSession:boolean;
    EditingSession:boolean;
    DeletingSession:boolean;
    CreatingSession:boolean;
    ErrorMessage:string
}
const initialStateSession: IStateSessions = {
    allSessions: [],
    loadingAllSessions:false,
    singleSession: null,
    loadingSingleSession:false,
    EditingSession:false,
    DeletingSession:false,
    CreatingSession:false,
    ErrorMessage:""
}

const SessionReducer = function (state = initialStateSession, action: {type:any; payload:any}):IStateSessions {
    switch (action.type) {
        case GET_ALL_SESSIONS:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:true,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_SUCCESS:
            return {
                allSessions: action.payload,
                loadingAllSessions:false,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_FAILED:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:false,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:action.payload
            };
        case GET_SINGLE_SESSION:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:true,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case GET_SINGLE_SESSION_SUCCESS:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: action.payload,
                loadingSingleSession:false,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case GET_SINGLE_SESSION_FAILED:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:false,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:action.payload
            };
        case CREATE_SESSION:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:true,
                ErrorMessage:state.ErrorMessage
            };
        case CREATE_SESSION_SUCCESS:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:false,
                ErrorMessage:state.ErrorMessage
            };
        case CREATE_SESSION_FAILED:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:false,
                ErrorMessage:action.payload
            };
        case EDIT_SESSION:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:true,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case EDIT_SESSION_SUCCESS:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:false,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case EDIT_SESSION_FAILED:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:false,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:action.payload
            };
        case DELETE_SESSION:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:true,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case DELETE_SESSION_SUCCESS:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:false,
                CreatingSession:state.CreatingSession,
                ErrorMessage:state.ErrorMessage
            };
        case DELETE_SESSION_FAILED:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:false,
                CreatingSession:state.CreatingSession,
                ErrorMessage:action.payload
            };
        case CLEAN_SINGLE_SESSION:
            return {
                allSessions: state.allSessions,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: action.payload,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:''
            };
        case CLEAN_ALL_SESSIONS:
            return {
                allSessions: action.payload,
                loadingAllSessions:state.loadingAllSessions,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                ErrorMessage:''
            };
        default:
            return state;
    }
}
export default SessionReducer;