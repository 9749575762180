import {VideoComponentStyled} from "./VideoComponentStyled";

interface IVideoComponentProps {
    videoTransmission: string;
}

export default function VideoComponent({
                                           videoTransmission,
                                       }: IVideoComponentProps): JSX.Element {

    return (
        <VideoComponentStyled>
            <iframe className="video" width="560" height="315"
                    src={videoTransmission}
                    title="YouTube video player" frameBorder="0"
                    allow="autoplay"

                    allowFullScreen></iframe>
        </VideoComponentStyled>
    );
}