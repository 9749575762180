import styled from "styled-components";

export const HeaderStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: .3fr 1fr .35fr;
  width: 100%;
  height: 20%;
  
  .img-logo {
    width: 16vw;
    margin: 1vw 0 0 2vw;
  }
  .text-progress-bar {
    position: absolute;
    left: 21vw;
    top: 4.5vw;
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    font-size: 1.6vw;
    z-index: 5;
  }
  .log-info {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 0.7vw;
    margin-top: 2.2vw;
    height: 68%;
    
    .log-info-text {
      display: flex;
      flex-direction: column;
      gap: .6vw;
      font-family: CoolveticaRg-Regular,serif;
      text-align: right;
      letter-spacing: 0;
      line-height: 1.2vw;
      color: #FFFFFF;
      opacity: 1;
      width: 10vw;
      margin: 1.7vw 0 0 2.2vw;
      
      .text-info {
        font-size: 1.5vw;
      }
      .text-info-nuber {
        font-size: 1vw;
      }
    }
  }
`