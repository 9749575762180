import styled from "styled-components";

export const SectionMainStyled = styled.div`
  height: 86%;
  background: #FFFFFF40 0% 0% no-repeat padding-box;
  border: 6px solid #FFFFFF;
  border-radius: 30px;
  opacity: 1;
  
  .container-card-bingo {
    width: 95%;
    height: 65%;
    margin: 2.2vw .7vw 0 1.7vw;
    overflow-y: scroll;
    
    .card-bingo-grid {
      width: 95%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(3, 1fr);
      align-items: center;
      justify-items: center;
    }
  }
`